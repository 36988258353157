import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Card, {
	CardBody,
	CardFooter,
	CardFooterLeft,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import Badge from '../../../components/bootstrap/Badge';
import FormUpdateTask from './FormUpdateTask';
import Icon from '../../../components/icon/Icon';
import { DueDate, readFromLocalStorage } from '../utils';
import Avatar from '../../../components/Avatar';

import USERS from '../../../common/data/userDummyData';
import QuillComponent from '../components/QuillComponent';
import { getQueryParams } from '../../../helpers/helpers';
import Actions from './Actions';

const Icons = {
	backlog: 'RateReview',
	open: 'FactCheck',
	in_progress: 'PendingActions',
	done: 'DoneOutline',
};

const TaskCard = ({ task, index, defaultOpen, onReload }) => {
	const navigate = useNavigate();
	const location = useLocation();

	const boardKey = readFromLocalStorage('board_key');

	const [openEdit, setOpenEdit] = useState(defaultOpen);

	const handleOpenModal = (code) => {
		// get and set new params
		const params = new URLSearchParams(location.search);
		params.set('task_code', code);
		navigate({ pathname: location.pathname, search: params.toString() }, { replace: true });

		// open modal
		setOpenEdit(true);
	};

	const handleCloseModal = () => {
		// get and remove task_code params
		const params = new URLSearchParams(location.search);
		params.delete('task_code');
		navigate({ pathname: location.pathname, search: params.toString() }, { replace: true });

		// close modal
		setOpenEdit(false);
	};

	return (
		<div>
			<Draggable draggableId={task.task_code} index={index}>
				{(drag) => (
					<div ref={drag.innerRef} {...drag.draggableProps} {...drag.dragHandleProps}>
						<Card
							shadow='none'
							borderSize={1}
							onClick={() => handleOpenModal(task.task_code)}
							className='rounded-2 cursor-pointer'>
							<CardHeader>
								<CardLabel>
									<CardTitle>{task?.title}</CardTitle>
								</CardLabel>
							</CardHeader>
							<CardBody className='pt-0'>
								<div className='row g-2 mb-3'>
									<div className='col-auto'>
										<small className='border border-info border-1 text-info fw-bold px-2 py-1 rounded-1'>
											<Icon icon='AttachFile' />{' '}
											{task?.attachment?.length || 0}
										</small>
									</div>
									<div className='col-auto'>
										<small className='border border-info border-1 text-info fw-bold px-2 py-1 rounded-1'>
											{DueDate(task.duedate_at)}
										</small>
									</div>
								</div>
								<div className='board-description'>
									<QuillComponent value={task.description} readOnly textOnly />
								</div>
							</CardBody>
							<CardFooter>
								<CardFooterLeft>
									<span className='fw-bold'>{`${boardKey}-${task.task_key}`}</span>
								</CardFooterLeft>
								<CardFooterRight>
									<Avatar
										src={USERS?.JOHN?.src}
										srcSet={USERS?.JHON?.srcSet}
										color={USERS?.JHON?.color}
										size={24}
										userName={task?.reporter?.name}
									/>
								</CardFooterRight>
							</CardFooter>
						</Card>
					</div>
				)}
			</Draggable>

			<FormUpdateTask
				isOpen={openEdit}
				setIsOpen={handleCloseModal}
				title={task.title}
				onReload={onReload}
			/>
		</div>
	);
};

TaskCard.propTypes = {
	task: PropTypes.instanceOf(Object).isRequired,
	index: PropTypes.number.isRequired,
	defaultOpen: PropTypes.bool,
	onReload: PropTypes.func,
};
TaskCard.defaultProps = {
	defaultOpen: false,
	onReload: () => {},
};

const TaskGroup = ({ group, onReload }) => {
	const { task_code: taskCode } = getQueryParams();

	return (
		<Droppable key={group.column_id} droppableId={group.column_id}>
			{(drop) => (
				<div {...drop.droppableProps} ref={drop.innerRef} className='col'>
					<Card
						stretch={group.task.length > 3}
						className={classNames('board-group', `shadow-3d-info`)}>
						<CardHeader>
							<CardLabel icon={Icons[group?.column_id]} iconColor='info'>
								<CardTitle>
									{group.column_title}{' '}
									<Badge color='info' isLight>
										{group?.task?.length || 0}
									</Badge>
								</CardTitle>
							</CardLabel>
						</CardHeader>
						<CardBody>
							{!group.task.length ? (
								<div>&nbsp;</div>
							) : (
								group.task.map((task, index) => (
									<TaskCard
										key={task.task_code}
										task={task}
										index={index}
										defaultOpen={task.task_code === taskCode}
										onReload={onReload}
									/>
								))
							)}
						</CardBody>
					</Card>
				</div>
			)}
		</Droppable>
	);
};

TaskGroup.propTypes = {
	group: PropTypes.instanceOf(Object).isRequired,
	onReload: PropTypes.func,
};
TaskGroup.defaultProps = {
	onReload: () => {},
};

const TaskBoard = ({ data, setData, onReload }) => {
	const currentUser = readFromLocalStorage('board_user');

	const onDragEnd = (result) => {
		const { source, destination } = result;

		if (!destination) return;

		const sourceGroup = data.find((group) => group.column_id === source.droppableId);
		const destinationGroup = data.find((group) => group.column_id === destination.droppableId);

		const sourceTasks = [...sourceGroup.task];
		const destinationTasks = [...destinationGroup.task];

		// get task moved
		const [movedTask] = sourceTasks.splice(source.index, 1);

		if (source.droppableId === destination.droppableId) {
			sourceTasks.splice(destination.index, 0, movedTask);
			setData(
				data.map((col) =>
					col.column_id === source.droppableId ? { ...col, task: sourceTasks } : col,
				),
			);
		} else {
			const status = {
				value: destinationGroup.column_id,
				label: destinationGroup.column_title,
			};

			movedTask.status = status;

			destinationTasks.splice(destination.index, 0, movedTask);
			setData(
				data.map((col) =>
					// eslint-disable-next-line no-nested-ternary
					col.column_id === source.droppableId
						? { ...col, task: sourceTasks }
						: col.column_id === destination.droppableId
						? { ...col, task: destinationTasks }
						: col,
				),
			);

			// update task here
			Actions.updateTask(movedTask.task_code, {
				status: JSON.stringify(status),
				actor: JSON.stringify(currentUser),
			})
				.then(() => {})
				.catch(() => {})
				.finally(() => {});
		}
	};

	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				{data.map((group) => (
					<TaskGroup key={group.column_id} group={group} onReload={onReload} />
				))}
			</div>
		</DragDropContext>
	);
};

TaskBoard.propTypes = {
	data: PropTypes.oneOfType([PropTypes.array]),
	setData: PropTypes.func,
	onReload: PropTypes.func,
};
TaskBoard.defaultProps = {
	data: [],
	setData: () => [],
	onReload: () => {},
};

export default TaskBoard;
