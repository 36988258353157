import React, { lazy } from 'react';

// define manual import for preventing hook restriction
const DUMMY = {
	CRUD: lazy(() => import('../views/tyo/CRUD')),
};

const Master = {
	Segment: lazy(() => import('../views/sodiq/MasterSegment')),
	MasterSettingEmail: lazy(() => import('../views/sodiq/MasterSettingEmail')),
};

const Transaction = {
	Outstanding: lazy(() => import('../views/sodiq/Outstanding')),
	Approval: lazy(() => import('../views/sodiq/Approval')),
	WorkingList: lazy(() => import('../views/sodiq/WorkingList')),
	Gatekeeper: lazy(() => import('../views/handri/Gatekeeper')),
	CreateWorklist: lazy(() => import('../views/sodiq/CreateWorklist')),
};

const Approval = {
	ApprovalList: lazy(() => import('../views/rio/approval-list/ApprovalList')),
};

const Board = {
	Workflow: lazy(() => import('../views/afif/board/Kanban')),
};

const contents = [
	{
		path: null,
		element: <DUMMY.CRUD />,
		index: 'CRUD',
		exact: true,
	},
	{
		path: null,
		element: <Master.Segment />,
		index: 'MasterSegment',
		exact: true,
	},
	{
		path: null,
		element: <Master.MasterSettingEmail />,
		index: 'MasterSettingEmail',
		exact: true,
	},
	{
		path: null,
		element: <Transaction.Outstanding />,
		index: 'Outstanding',
		exact: true,
	},
	{
		path: null,
		element: <Transaction.Approval />,
		index: 'Approval',
		exact: true,
	},
	{
		path: null,
		element: <Approval.ApprovalList />,
		index: 'ApprovalList',
		exact: true,
	},
	{
		path: null,
		element: <Transaction.WorkingList />,
		index: 'WorkingList',
		exact: true,
	},
	{
		path: null,
		element: <Transaction.Gatekeeper />,
		index: 'Gatekeeper',
		exact: true,
	},
	{
		path: null,
		element: <Transaction.CreateWorklist />,
		index: 'CreateWorklist',
		exact: true,
	},
	{
		path: null,
		element: <Board.Workflow />,
		index: 'BoardWorkflow',
		exact: true,
	},
];
export default contents;
