import axios from 'axios';
import authHeader from '../auth-header';

const index = {};

index.read = async (query) => {
	return axios.get(`${process.env.REACT_APP_API}master/priority`, {
		headers: await authHeader(),
		params: query,
	});
};

export default index;
