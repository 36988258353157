import React from 'react';
import { ClipLoader } from 'react-spinners';
import PropTypes from 'prop-types';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../../components/bootstrap/Modal';
import './FilePreview.scss';

export const ImagePreview = ({ isLoading, isOpen, setIsOpen, title, img }) => {
	return (
		<Modal
			titleId='crud'
			isOpen={isOpen}
			setIsOpen={setIsOpen}
			size='xl'
			isCentered
			isFocus={false}
			enableEscape={false}
			isStaticBackdrop>
			<ModalHeader setIsOpen={setIsOpen}>
				<ModalTitle id='crud'>{title}</ModalTitle>
			</ModalHeader>
			<ModalBody>
				<div
					className='spinner-container'
					style={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
					}}>
					<ClipLoader color='#123abc' loading={isLoading} size={50} />
				</div>
				<div className='modal-preview'>
					<img
						src={img}
						alt='Preview'
						className='modal-image-preview '
						style={{
							display: isLoading ? 'none' : 'block',
							maxWidth: '100%',
							maxHeight: '100%',
						}}
					/>
				</div>
			</ModalBody>
		</Modal>
	);
};

ImagePreview.propTypes = {
	isOpen: PropTypes.bool,
	setIsOpen: PropTypes.func,
	title: PropTypes.string,
	img: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	isLoading: PropTypes.bool,
};
ImagePreview.defaultProps = {
	isOpen: false,
	setIsOpen: () => false,
	title: 'Preview',
	img: '',
	isLoading: false,
};

export const PDFPreview = (response) => {
	// Convert Base64 to Blob
	const byteCharacters = atob(response?.file);
	const byteNumbers = new Array(byteCharacters.length);
	for (let i = 0; i < byteCharacters.length; i += 1) {
		byteNumbers[i] = byteCharacters.charCodeAt(i);
	}
	const byteArray = new Uint8Array(byteNumbers);
	const blob = new Blob([byteArray], { type: response?.mimetype });

	// Create Object URL
	const objectUrl = URL.createObjectURL(blob);

	// Open in a new tab
	window.open(objectUrl, '_blank');
};

export const PDFPreviewObject = (object) => {
	const reader = new FileReader();
	reader.onload = (event) => {
		const pdfBlob = new Blob([event.target.result], { type: 'application/pdf' });
		// Create Object URL
		const objectUrl = URL.createObjectURL(pdfBlob);
		// Open in a new tab
		window.open(objectUrl, '_blank');
	};
	reader.readAsArrayBuffer(object);
};

// xls, doc, others
export const DOCDownload = (response, filename) => {
	// Convert Base64 to Blob
	const byteCharacters = atob(response?.file);
	const byteNumbers = new Array(byteCharacters.length);
	for (let i = 0; i < byteCharacters.length; i += 1) {
		byteNumbers[i] = byteCharacters.charCodeAt(i);
	}
	const byteArray = new Uint8Array(byteNumbers);
	const blob = new Blob([byteArray], { ype: response?.mimetype });

	// Create download link
	const url = URL.createObjectURL(blob);
	const a = document.createElement('a');
	a.href = url;
	a.download = filename || `file.${response?.extension}`;
	document.body.appendChild(a);

	a.click();

	document.body.removeChild(a);
	URL.revokeObjectURL(url);
};

export const DOCDownloadObject = (object) => {
	const reader = new FileReader();
	reader.onload = (event) => {
		const blob = new Blob([event.target.result], { type: object?.type });
		// Create Object URL
		const objectUrl = URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.href = objectUrl;
		a.download = object?.name;
		document.body.appendChild(a);

		a.click();

		document.body.removeChild(a);
		URL.revokeObjectURL(objectUrl);
	};

	reader.readAsArrayBuffer(object);
};

export const DownloadFileObject = (file) => {
	if (!file) return;

	const url = URL.createObjectURL(file);
	const link = document.createElement('a');
	link.href = url;
	link.download = file.name;
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
	URL.revokeObjectURL(url);
};

export const DownloadFile = (file) => {
	if (!file.base64) return;

	const link = document.createElement('a');
	link.href = file.base64;
	link.download = file.fileName;
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};
