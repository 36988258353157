import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../../../components/bootstrap/Modal';
import Button from '../../../../components/bootstrap/Button';

const DetailModal = ({ item, isOpen, setIsOpen, zIndex }) => {
	const { date, created_at } = useMemo(
		() => ({
			date: moment(item.date),
			created_at: moment(item.created_at),
		}),
		[item],
	);

	return (
		<Modal
			titleId='ModalScheduleDetails'
			isOpen={isOpen}
			setIsOpen={setIsOpen}
			isFocus={false}
			enableEscape={false}
			isCentered
			isStaticBackdrop
			zIndex={zIndex}>
			<ModalHeader setIsOpen={setIsOpen}>
				<ModalTitle id='ScheduleDetails'>Schedule Details</ModalTitle>
			</ModalHeader>
			<ModalBody>
				<section className='row px-3 py-2'>
					<div className='col-6 fw-bold'>Title</div>
					<div className='col-6'>{item.title}</div>
				</section>
				<section className='row px-3 py-2'>
					<div className='col-6 fw-bold'>Date</div>
					<div className='col-6'>{date.format('DD MMMM YYYY')}</div>
				</section>
				<section className='row px-3 py-2'>
					<div className='col-6 fw-bold'>Time</div>
					<div className='col-6'>
						<p className='mb-1'>
							{item.is_all_day
								? 'All Day'
								: `${item.time?.start} - ${item.time?.end}`}
						</p>
						<p className='mb-1'>{item.repeat?.label}</p>
					</div>
				</section>
				<section className='row px-3 py-2'>
					<div className='col-6 fw-bold'>Reminders</div>
					<div className='col-6'>
						{item.reminder?.map((reminder) => (
							<p key={reminder} className='mb-1'>
								{reminder?.label}
							</p>
						))}
					</div>
				</section>
				<section className='row px-3 py-2'>
					<div className='col-6 fw-bold'>Assigned to</div>
					<div className='col-6'>
						{item.assign_to?.map((assignee) => (
							<p key={assignee?.username} className='mb-1'>
								{assignee?.username}-{assignee?.name}
							</p>
						))}
					</div>
				</section>
				<section className='row px-3 py-2'>
					<div className='col-6 fw-bold'>Location</div>
					<div className='col-6'>{item.location}</div>
				</section>
				<section className='row px-3 py-2'>
					<div className='col-6 fw-bold'>Created at</div>
					<div className='col-6'>{created_at.format('DD MMMM YYYY, hh:mm')}</div>
				</section>
				<section className='row px-3 py-2'>
					<div className='col-6 fw-bold'>Description</div>
					<div className='col-6'>{item.description}</div>
				</section>
				<section className='row px-3 py-2'>
					<div className='col-6 fw-bold'>Board</div>
					<div className='col-6'>{item.board?.title}</div>
				</section>
				{item?.type === 'task' && (
					<section className='row px-3 py-2'>
						<div className='col-6 fw-bold'>Task</div>
						<div className='col-6'>{item.task?.title}</div>
					</section>
				)}
			</ModalBody>
			<ModalFooter>
				<Button
					color='info'
					isLink
					type='reset'
					onClick={() => setIsOpen(false)}
					className='m-1'>
					Close
				</Button>
			</ModalFooter>
		</Modal>
	);
};
DetailModal.propTypes = {
	item: PropTypes.instanceOf(Object),
	isOpen: PropTypes.bool,
	setIsOpen: PropTypes.func,
	zIndex: PropTypes.number,
};
DetailModal.defaultProps = {
	item: {},
	isOpen: false,
	setIsOpen: () => false,
	zIndex: 1061,
};

export default DetailModal;
