import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import OffCanvas, {
	OffCanvasBody,
	OffCanvasHeader,
	OffCanvasTitle,
} from '../../../components/bootstrap/OffCanvas';
import Button from '../../../components/bootstrap/Button';
import Label from '../../../components/bootstrap/forms/Label';
import Input from '../../../components/bootstrap/forms/Input';
import Checks, { ChecksGroup } from '../../../components/bootstrap/forms/Checks';
import Alert from '../../../components/bootstrap/Alert';
import Icon from '../../../components/icon/Icon';
import { copyUrlToClipboard, readFromLocalStorage, writeToLocalStorage } from '../utils';
import Avatar from '../../../components/Avatar';

import USERS from '../../../common/data/userDummyData';
import Badge from '../../../components/bootstrap/Badge';
import Actions from './Actions';

const COLOR_USERS = {
	guest: 'light',
	spectator: 'warning',
	member: 'success',
	owner: 'info',
};

const ChecksComponent = ({ id, list, value, onChange, isInline, description }) => {
	return (
		<div>
			<ChecksGroup isInline={isInline}>
				{list.map((item) => (
					<Checks
						name={id}
						key={`check-${id}-${item.value}`}
						type='radio'
						id={`${id}${item.value}`}
						label={item.label}
						value={item.value}
						checked={value}
						onChange={onChange}
					/>
				))}
			</ChecksGroup>

			{description && (
				<div className='mt-2'>
					<Alert isLight color='info'>
						<div className='d-flex align-items-center'>
							<Icon icon='Info' color='info' size='2x' />
							<div className='ms-2'>{description}</div>
						</div>
					</Alert>
				</div>
			)}
		</div>
	);
};

ChecksComponent.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	list: PropTypes.oneOfType([PropTypes.array]),
	value: PropTypes.string,
	onChange: PropTypes.func,
	isInline: PropTypes.bool,
	description: PropTypes.string,
};
ChecksComponent.defaultProps = {
	id: 'id',
	list: [],
	value: '',
	onChange: () => {},
	isInline: false,
	description: false,
};

const FieldComponents = ({ label, value, onChange, pattern }) => {
	const [data, setData] = useState(value);
	const [showEdit, setShowEdit] = useState(false);

	const [isValid, setIsValid] = useState(true);
	const [invalidMessaage, setInvalidMessage] = useState('');

	const handleSave = () => {
		if (!data || !data?.trim()) {
			setIsValid(false);
			setInvalidMessage('Required');
		} else {
			setIsValid(true);
			setInvalidMessage('');

			let newValue = data?.trim();
			if (pattern) newValue = newValue.replace(/ /g, '_');
			onChange(newValue);
			setShowEdit(false);
		}
	};

	const handleCancel = () => {
		setData(value);
		setShowEdit(false);
		setIsValid(true);
		setInvalidMessage('');
	};

	return (
		<div>
			<div className='d-flex align-items-center py-1'>
				<div className='flex-grow-1'>
					<Label>{label}</Label>
				</div>

				{!showEdit && (
					<div>
						<Button
							isOutline
							icon='Edit'
							color='info'
							type='button'
							size='sm'
							className='mx-1'
							onClick={() => setShowEdit(true)}
						/>
					</div>
				)}
			</div>

			{!showEdit && <div className='py-1'>{value}</div>}

			{showEdit && (
				<div className='py-1'>
					<Input
						value={data}
						onChange={(e) => {
							if (pattern) {
								if (pattern.test(e.target.value)) {
									setData(e.target.value);
								}
							} else {
								setData(e.target.value);
							}
						}}
						isValid={isValid}
						isTouched={!!invalidMessaage}
						invalidFeedback={invalidMessaage}
					/>
				</div>
			)}

			{showEdit && (
				<div className='py-1'>
					<Button
						color='info'
						type='button'
						size='sm'
						className='mx-1'
						onClick={handleSave}>
						Save
					</Button>

					<Button
						isLink
						color='info'
						type='button'
						size='sm'
						className='mx-1'
						onClick={handleCancel}>
						Cancel
					</Button>
				</div>
			)}
		</div>
	);
};

FieldComponents.propTypes = {
	label: PropTypes.string,
	value: PropTypes.string,
	onChange: PropTypes.func,
	pattern: PropTypes.string,
};
FieldComponents.defaultProps = {
	label: 'Label',
	value: '',
	onChange: () => {},
	pattern: null,
};

const BoardMenu = ({ isOpen, setIsOpen, data, onReload }) => {
	const currentUser = readFromLocalStorage('board_user');
	const listMembers = readFromLocalStorage('board_members');

	const [menu] = useState({
		Main: 'Main',
		BoardInformation: 'Board Information',
		Activity: 'Activity',
		TrashBin: 'Trash Bin',
	});

	const formik = useFormik({
		initialValues: {
			title: data?.title || '',
			board_key: data?.board_key || '',
			members: data?.members || [],
			visibility: data?.visibility || '',
		},
		enableReinitialize: true,
		onReset: () => {},
		onSubmit: () => {},
	});

	const [activeMenu, setActiveMenu] = useState(menu.Main);

	const [listVisibility] = useState([
		{
			value: 'private',
			label: 'Private',
			description: 'Only board members can see this board',
		},
		{
			value: 'public',
			label: 'Public',
			description: 'Any one can see this board, only board members can edit',
		},
	]);

	const [isChange, setIsChange] = useState(false);

	const handleClose = () => {
		if (isChange) {
			setIsChange(false);
			onReload();
		}
		setActiveMenu(menu.Main);
		setIsOpen(false);
	};

	const handleChange = (key, value) => {
		// update here
		Actions.updateBoard(data?.trans_code, {
			[key]: value,
			actor: JSON.stringify(currentUser),
		})
			.then(() => {
				formik.setFieldValue(key, value);
				if (key === 'board_key') {
					writeToLocalStorage('board_key', value);
				}
				setIsChange(true);
			})
			.catch(() => {
				setIsChange(false);
			})
			.finally(() => {});
	};

	return (
		<OffCanvas
			isOpen={isOpen}
			setOpen={handleClose}
			titleId='canvasMennu'
			isBodyScroll
			placement='end'>
			<OffCanvasHeader
				onBack={() => setActiveMenu(menu.Main)}
				showBack={activeMenu !== menu.Main}
				titleCenter
				setOpen={handleClose}>
				<OffCanvasTitle id='canvasMennu'>{activeMenu}</OffCanvasTitle>
			</OffCanvasHeader>
			<OffCanvasBody>
				<div className='border-bottom mb-3' />

				{/* MAIN MENU */}
				{activeMenu === menu.Main && (
					<div className='row'>
						<div className='col-12'>
							<Button
								icon='InfoOutline'
								color='info'
								onClick={() => setActiveMenu(menu.BoardInformation)}
								className='w-100 p-2 text-start d-flex align-items-center'
								isLink>
								Board Information
							</Button>
						</div>

						<div className='col-12'>
							<Button
								icon='Restore'
								color='info'
								onClick={() => setActiveMenu(menu.Activity)}
								className='w-100 p-2 text-start d-flex align-items-center'
								isLink>
								Activity
							</Button>
						</div>

						<div className='col-12'>
							<Button
								icon='ContentCopy'
								color='info'
								onClick={() => copyUrlToClipboard(true)}
								className='w-100 p-2 text-start d-flex align-items-center'
								isLink>
								Copy Board Link
							</Button>
						</div>

						<div className='col-12'>
							<Button
								icon='Delete'
								color='info'
								onClick={() => setActiveMenu(menu.TrashBin)}
								className='w-100 p-2 text-start d-flex align-items-center'
								isLink>
								Trash Bin
							</Button>
						</div>

						<div className='col-12'>
							<Button
								icon='Close'
								color='info'
								className='w-100 p-2 text-start d-flex align-items-center'
								isLink>
								Close Board
							</Button>
						</div>

						<div className='col-12'>
							<Button
								icon='Logout'
								color='info'
								className='w-100 p-2 text-start d-flex align-items-center'
								isLink>
								Leave Board
							</Button>
						</div>
					</div>
				)}

				{/* BOARD INFORMATION */}
				{activeMenu === menu.BoardInformation && (
					<div>
						<FieldComponents
							label='Board Title'
							id='title'
							value={formik.values.title}
							onChange={(e) => handleChange('title', e)}
						/>
						<div className='border-bottom my-4' />

						<FieldComponents
							label='Board Key'
							id='board_key'
							value={formik.values.board_key}
							onChange={(e) => handleChange('board_key', e)}
							pattern={/^[A-Za-z\s]*$/}
						/>
						<div className='border-bottom my-4' />

						<ChecksComponent
							id='visibility'
							list={listVisibility}
							value={formik.values.visibility}
							onChange={(e) => handleChange('visibility', e.target.value)}
							isInline
							description={
								listVisibility.find((f) => f.value === formik.values.visibility)
									?.description
							}
						/>
						<div className='border-bottom my-4' />

						{listMembers.map((user) => (
							<div key={user?.username} className='d-flex align-items-center'>
								<div className='flex-grow-1'>
									<div className='d-flex'>
										<div className='flex-shrink-0'>
											<Avatar
												src={USERS.JOHN.src}
												srcSet={USERS.JOHN.srcSet}
												color={USERS.JOHN.color}
												size={36}
											/>
										</div>
										<div className='mx-2'>
											<div>{user?.name}</div>
											<div className='small text-muted'>{user?.username}</div>
										</div>
									</div>
								</div>
								<div className='mx-1'>
									<Badge color={user?.color || COLOR_USERS[user?.role]}>
										{user?.role}
									</Badge>
								</div>
							</div>
						))}
						<div className='border-bottom my-4' />
					</div>
				)}

				{/* ACTIVITY */}
				{activeMenu === menu.Activity && <div>Activity</div>}

				{/* TRASH BIN */}
				{activeMenu === menu.TrashBin && <div>Trash Bin</div>}
			</OffCanvasBody>
		</OffCanvas>
	);
};

BoardMenu.propTypes = {
	isOpen: PropTypes.bool,
	setIsOpen: PropTypes.func,
	data: PropTypes.oneOfType([PropTypes.object]),
	onReload: PropTypes.func,
};
BoardMenu.defaultProps = {
	isOpen: false,
	setIsOpen: () => false,
	data: null,
	onReload: () => {},
};

export default BoardMenu;
