import axios from 'axios';
import authHeader from './auth-header';

const index = {};

index.read = async (query) => {
	return axios.get(`${process.env.REACT_APP_API}file`, {
		headers: await authHeader(),
		params: query,
	});
};

index.getFile = async (onedriveId) => {
	return axios.get(`${process.env.REACT_APP_API}file/get-file/${onedriveId}`, {
		headers: await authHeader(),
	});
};

index.getFiles = async (payload) => {
	return axios.post(`${process.env.REACT_APP_API}file/get-files`, payload, {
		headers: await authHeader(),
	});
};

index.postFiles = async (payload) => {
	return axios.post(`${process.env.REACT_APP_API}file/post-files`, payload, {
		headers: await authHeader(),
	});
};

export default index;
