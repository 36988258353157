import React from 'react';
import PropTypes from 'prop-types';
import LoadingSVG from '../../../assets/loading.svg';
import './LoadingOverlay.scss';

const LoadingOverlay = ({ loading }) => {
	return loading ? (
		<div className='loading-overlay'>
			<div className='image-container'>
				<img src={LoadingSVG} alt='loading' className='image-preview' />
			</div>
		</div>
	) : null;
};

LoadingOverlay.propTypes = {
	loading: PropTypes.bool,
};

LoadingOverlay.defaultProps = {
	loading: false,
};

export default LoadingOverlay;
