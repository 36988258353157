import Swal from 'sweetalert2';
import BoardModule from '../../../modules/bakti/BoardModule';
import TaskModule from '../../../modules/bakti/TaskModule';

/**
 *
 * @param {String} boardCode
 * @param {Object} payload
 * @returns
 */
const updateBoard = (boardCode, payload) => {
	return new Promise((resolve, reject) => {
		try {
			BoardModule.updateOneByBoardCode(boardCode, payload)
				.then((respponse) => {
					resolve(respponse);
				})
				.catch((err) => {
					Swal.fire({ heightAuto: false, title: 'Warning!', text: err, icon: 'error' });
					reject(new Error(err));
				});
		} catch (e) {
			reject(new Error(e.message));
		}
	});
};

/**
 *
 * @param {String} boardCode
 * @param {Object} payload
 * @returns
 */
const leaveBoard = (boardCode, payload) => {
	return new Promise((resolve, reject) => {
		try {
			BoardModule.leaveOneByBoardCode(boardCode, payload)
				.then((respponse) => {
					resolve(respponse);
				})
				.catch((err) => {
					Swal.fire({ heightAuto: false, title: 'Warning!', text: err, icon: 'error' });
					reject(new Error(err));
				});
		} catch (e) {
			reject(new Error(e.message));
		}
	});
};

/**
 *
 * @param {String} boardCode
 * @param {Object} payload
 * @returns
 */
const closeBoard = (boardCode, payload) => {
	return new Promise((resolve, reject) => {
		try {
			BoardModule.closeOneByBoardCode(boardCode, payload)
				.then((respponse) => {
					resolve(respponse);
				})
				.catch((err) => {
					Swal.fire({ heightAuto: false, title: 'Warning!', text: err, icon: 'error' });
					reject(new Error(err));
				});
		} catch (e) {
			reject(new Error(e.message));
		}
	});
};

/**
 *
 * @param {String} boardCode
 * @param {Object} payload
 * @returns
 */
const createTask = (boardCode, payload) => {
	return new Promise((resolve, reject) => {
		try {
			TaskModule.create(boardCode, payload)
				.then((respponse) => {
					resolve(respponse);
				})
				.catch((err) => {
					Swal.fire({ heightAuto: false, title: 'Warning!', text: err, icon: 'error' });
					reject(new Error(err));
				});
		} catch (e) {
			reject(new Error(e.message));
		}
	});
};

/**
 *
 * @param {String} taskCode
 * @param {Object} payload
 * @returns
 */
const updateTask = (taskCode, payload) => {
	return new Promise((resolve, reject) => {
		try {
			TaskModule.update(taskCode, payload)
				.then((respponse) => {
					resolve(respponse);
				})
				.catch((err) => {
					Swal.fire({ heightAuto: false, title: 'Warning!', text: err, icon: 'error' });
					reject(new Error(err));
				});
		} catch (e) {
			reject(new Error(e.message));
		}
	});
};

/**
 *
 * @param {String} boardCode
 * @param {Object} payload
 * @returns
 */
const deleteTask = (boardCode, payload) => {
	return new Promise((resolve, reject) => {
		try {
			TaskModule.destroy(boardCode, payload)
				.then((respponse) => {
					resolve(respponse);
				})
				.catch((err) => {
					Swal.fire({ heightAuto: false, title: 'Warning!', text: err, icon: 'error' });
					reject(new Error(err));
				});
		} catch (e) {
			reject(new Error(e.message));
		}
	});
};

/**
 *
 * @param {String} taskCode
 * @param {Object} payload
 * @returns
 */
const createComment = (taskCode, payload) => {
	return new Promise((resolve, reject) => {
		try {
			TaskModule.createComment(taskCode, payload)
				.then((respponse) => {
					resolve(respponse);
				})
				.catch((err) => {
					Swal.fire({ heightAuto: false, title: 'Warning!', text: err, icon: 'error' });
					reject(new Error(err));
				});
		} catch (e) {
			reject(new Error(e.message));
		}
	});
};

/**
 *
 * @param {String} id
 * @param {String} taskCode
 * @param {Object} payload
 * @returns
 */
const updateComment = (id, taskCode, payload) => {
	return new Promise((resolve, reject) => {
		try {
			TaskModule.updateComment(id, taskCode, payload)
				.then((respponse) => {
					resolve(respponse);
				})
				.catch((err) => {
					Swal.fire({ heightAuto: false, title: 'Warning!', text: err, icon: 'error' });
					reject(new Error(err));
				});
		} catch (e) {
			reject(new Error(e.message));
		}
	});
};

/**
 *
 * @param {String} id
 * @param {String} taskCode
 * @param {Object} payload
 * @returns
 */
const deleteComment = (id, taskCode, payload) => {
	return new Promise((resolve, reject) => {
		try {
			TaskModule.deleteComment(id, taskCode, payload)
				.then((respponse) => {
					resolve(respponse);
				})
				.catch((err) => {
					Swal.fire({ heightAuto: false, title: 'Warning!', text: err, icon: 'error' });
					reject(new Error(err));
				});
		} catch (e) {
			reject(new Error(e.message));
		}
	});
};

export default {
	updateBoard,
	leaveBoard,
	closeBoard,
	createTask,
	updateTask,
	deleteTask,
	createComment,
	updateComment,
	deleteComment,
};
