import Service from '../services/organizations.service';

const index = {};

index.read = (query) => {
	return Service.read(query).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();

			return Promise.reject(message);
		},
	);
};

index.loadOptions = (search, orgNames, showAll) => {
	return new Promise((resolve) => {
		const query = { search, perPage: 30, showAll };
		if (orgNames) {
			query.orgNames = orgNames;
		}
		Service.read(query)
			.then((response) => {
				resolve(response.data.foundData || []);
			})
			.catch(() => {
				resolve([]);
			});
	});
};

export default index;
