import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.css';

const DatePickerField = ({
	value,
	dateFormat,
	onChange,
	isRequired,
	className,
	placeholder,
	isSubmitted,
	invalidFeedback,
	readOnly,
	showMonthYearPicker,
	showYearPicker,
	minDate,
	maxDate,
	...props
}) => {
	const [dateF] = useState(dateFormat || 'yyyy-mm-dd');
	const [defaultFormat] = useState('YYYY-MM-DDTHH:mm:ssZ');
	const [valueDate, setValueDate] = useState(null);

	useEffect(() => {
		if (value && moment(value).isValid()) {
			const checkString = typeof value === 'string' && value.search('Z');
			const valFo = checkString > 0 ? defaultFormat : 'YYYY-MM-DD';
			const newValue = moment(value, valFo).tz('Asia/Jakarta').format('YYYY-MM-DD');

			setValueDate(newValue);
		} else {
			setValueDate(null);
		}
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);

	return (
		<div className='customDatePickerWidth'>
			<DatePicker
				selected={(valueDate && new Date(valueDate)) || null}
				onChange={(val) => onChange(val)}
				placeholderText={placeholder}
				dateFormat={dateF}
				className={classNames(
					'form-control',
					'form-control-md',
					invalidFeedback && isSubmitted && 'is-invalid',
					className,
				)}
				required={isRequired}
				ariaRequired={isRequired}
				ariaInvalid
				disabledKeyboardNavigation
				readOnly={readOnly}
				showMonthYearPicker={showMonthYearPicker}
				showYearPicker={showYearPicker}
				minDate={minDate}
				maxDate={maxDate}
				{...props}
			/>

			{invalidFeedback && isSubmitted && (
				<div style={{ color: '#f35421', fontSize: '0.875rem' }} className='mt-1'>
					{invalidFeedback}
				</div>
			)}
		</div>
	);
};

DatePickerField.propTypes = {
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	dateFormat: PropTypes.string,
	onChange: PropTypes.func,
	invalidFeedback: PropTypes.string,
	className: PropTypes.string,
	placeholder: PropTypes.string,
	isRequired: PropTypes.bool,
	isSubmitted: PropTypes.bool,
	readOnly: PropTypes.bool,
	showMonthYearPicker: PropTypes.bool,
	showYearPicker: PropTypes.bool,
	minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
DatePickerField.defaultProps = {
	value: null,
	dateFormat: 'yyyy-mm-dd',
	onChange: () => {},
	invalidFeedback: null,
	className: null,
	placeholder: 'YYYY-MM-DD',
	isRequired: false,
	isSubmitted: false,
	readOnly: false,
	showMonthYearPicker: false,
	showYearPicker: false,
	minDate: null,
	maxDate: null,
};

export default DatePickerField;
