import axios from 'axios';
import authHeader from '../auth-header';

const API_URL_DEFAULT = process.env.REACT_APP_API;
const APP_MODEL = 'outstanding';
const publicKey = process.env.REACT_APP_PUBLIC_KEY;
const token = JSON.parse(localStorage.getItem('accessToken'));
const applicationToken = JSON.parse(localStorage.getItem('appToken'));

const read = async (query_string) => {
	return axios.get(`${API_URL_DEFAULT}${APP_MODEL}/?${query_string}`, {
		headers: await authHeader(),
	});
};

const readNew = async (query_string) => {
	return axios.get(`${API_URL_DEFAULT}${APP_MODEL}/new?${query_string}`, {
		headers: await authHeader(),
	});
};

const feedback = async (payload) => {
	return axios.post(`${API_URL_DEFAULT}${APP_MODEL}/feedback`, payload, {
		headers: {
			'x-public-key': publicKey,
			'x-application-token': `Bearer ${applicationToken.appToken}`,
			'x-user-token': `Bearer ${token.accessToken}`,
			'Content-Type': 'application/x-www-form-urlencoded',
			'Access-Control-Allow-Origin': '*',
		},
	});
};

const timeline = async (query_string) => {
	return axios.get(`${API_URL_DEFAULT}${APP_MODEL}/timeline/?${query_string}`, {
		headers: await authHeader(),
	});
};

const trash = async (payload) => {
	return axios.post(`${API_URL_DEFAULT}${APP_MODEL}/trash/`, payload, {
		headers: await authHeader(),
	});
};

const preview = async (query_string) => {
	return axios.get(`${API_URL_DEFAULT}${APP_MODEL}/preview/?${query_string}`, {
		headers: await authHeader(),
	});
};

const deptByOrg = async (query_string) => {
	return axios.get(`${API_URL_DEFAULT}${APP_MODEL}/dept-by-org/?${query_string}`, {
		headers: await authHeader(),
	});
};

const create = async (payload) => {
	return axios.post(`${API_URL_DEFAULT}${APP_MODEL}`, payload, {
		headers: await authHeader(),
	});
};

export default { read, feedback, timeline, trash, preview, deptByOrg, create, readNew };
