import axios from 'axios';
import authHeader from './auth-header';

const API_URL_DEFAULT = process.env.REACT_APP_API;

const getFileTypeAndLimit = async (query) => {
	return axios.get(`${API_URL_DEFAULT}general-setting/file-type-and-limit`, {
		headers: await authHeader(),
		params: query,
	});
};

export default { getFileTypeAndLimit };
