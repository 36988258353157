import React, { useState, useEffect, useRef } from 'react';
import { Formik, Form } from 'formik';
import { useParams, useNavigate } from 'react-router-dom';

import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import PageLayoutHeader from '../../pages/common/Headers/PageLayoutHeader';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
	CardFooter,
} from '../../components/bootstrap/Card';
import Input from '../../components/bootstrap/forms/Input';
import Button from '../../components/bootstrap/Button';
import Label from '../../components/bootstrap/forms/Label';
import OutstandingModule from '../../modules/sodiq/OutstandingModule';
import MasterSegmentModule from '../../modules/sodiq/MasterSegmentModule';
import showNotification from '../../components/extras/showNotification';
import CustomAsyncSelect from '../../components/custom/CustomAsyncSelect';
import { getRequester } from '../../helpers/helpers';
import Textarea from '../../components/bootstrap/forms/Textarea';
import Spinner from '../../components/bootstrap/Spinner';
import Icon from '../../components/icon/Icon';

const loadOptionSegment = async (find) => {
	return new Promise((resolve) => {
		setTimeout(() => {
			resolve(fetchDataAsyncSelect(find));
		}, 1000);
	});
};

const fetchDataAsyncSelect = async (find) => {
	if (find?.length >= 3) {
		const params = { search: find, page: 1, sizePerPage: 100 };
		const result = await MasterSegmentModule.read(new URLSearchParams(params));
		if (result.data) {
			const temp = [];
			result.data.map((item) => {
				temp.push({ value: item.segment, label: item.segment, data: item });
				return item;
			});
			return temp;
		}
	}
	return [];
};

const fetchDataOption = async (setSelectOptionSegment) => {
	const res = await getOrganization();
	setSelectOptionSegment(res);
};

const getOrganization = async () => {
	const res = await MasterSegmentModule.readOrganizationWithMasterSegment();
	return res;
};

const Main = () => {
	const navigate = useNavigate();
	const { username } = getRequester();

	const { t } = useTranslation('crud');

	const [title] = useState({ title: 'Outstanding' });
	const { transactionid } = useParams();

	const initialValues = {};
	const [initialStateValues, setInitialStateValues] = useState({});
	const [selectedPIC, setSelectedPIC] = useState(null);
	const [optionPIC, setSelectOptionPIC] = useState([]);
	const [selectedSegment, setSelectedSegment] = useState(null);
	const [optionSegment, setSelectOptionSegment] = useState([]);
	const [files, setFiles] = useState(null);
	const [detailEmail, setDetailEmail] = useState({});
	const [loading, setLoading] = useState(false);
	const selectInputPIC = useRef(null);
	const selectInputSegment = useRef(null);

	useEffect(() => {
		// eslint-disable-next-line react-hooks/exhaustive-deps
		fetchDataOption(setSelectOptionSegment, setSelectOptionPIC);

		fetchData(transactionid);
	}, [transactionid]);

	const onChangePIC = (select) => {
		setSelectedPIC(select);
	};

	const loadOptionsPICBySegment = async (find) => {
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve(fetchDataAsyncSelectPIC(find));
			}, 1000);
		});
	};

	const fetchDataAsyncSelectPIC = async (find) => {
		if (selectedSegment.is_org) {
			const res = await MasterSegmentModule.readPIC(
				new URLSearchParams({ department: selectedSegment.value, search: find }),
			);
			return res;
		}
		const res = optionPIC.filter((e) => e.label.includes(find));
		return res;
	};

	const fetchData = async (transaction_code) => {
		const params = {
			transaction_code,
		};
		return OutstandingModule.readDetail(new URLSearchParams(params))
			.then((res) => {
				setDetailEmail(res);
				setInitialStateValues(res);
			})
			.catch((err) => {
				showNotification('Warning!', err, 'danger');
			});
	};

	const onChangeSegment = (select) => {
		setSelectOptionPIC(null);
		setSelectedSegment(select);
		setSelectedPIC(null);
		selectInputPIC?.current?.clearValue();
		if (!select?.is_org) {
			const temp = [].concat(
				...select.data.pic_details.map((pic) => {
					return {
						value: pic.name,
						label: `(${pic.nik}) ${pic.name}`,
						nik: pic.nik,
						email: pic.email,
					};
				}),
			);
			setSelectOptionPIC(temp);
		}
	};

	const handleUploadFile = (e) => {
		if (e.target.files) {
			const arr = Array.from(e.target.files);
			const tempFiles = [];
			arr.forEach((content) => {
				tempFiles.push(content);
			});
			setFiles(tempFiles);
		}
	};

	const onSubmitFeedback = (values) => {
		console.log(selectedPIC.value);
		console.log(selectedPIC.nik);
		console.log(selectedPIC.email);
		console.log(selectedSegment.value);
		if (selectedPIC != null && selectedSegment != null && values.comment != null) {
			Swal.fire({
				title: 'Are you sure?',
				text: 'Please check your entries !',
				icon: 'info',
				showCancelButton: true,
				confirmButtonText: 'Yes',
			}).then((result) => {
				if (result.value) {
					setLoading(true);
					const { person_name } = getRequester();
					const formData = new FormData();
					if (files != null && files.length > 0) {
						files.forEach((item) => {
							formData.append('files', item);
						});
					}
					if (initialValues?.current_pic?.nik !== selectedPIC.nik) {
						const picc = optionPIC.filter((e) => e.nik !== selectedPIC.nik);
						if (picc.length > 0) {
							formData.append('pic_cc', JSON.stringify(picc));
						}
					}
					formData.append('_id', values._id);
					formData.append('segment', selectedSegment.value);
					formData.append('comment', values.comment);
					formData.append('pic_name', selectedPIC.value);
					formData.append('pic_nik', selectedPIC.nik);
					formData.append('pic_email', selectedPIC.email);
					formData.append('transaction_code', values.transaction_code);
					formData.append('requester', username);
					formData.append('person_name', person_name);
					formData.append('status', values.status);

					OutstandingModule.feedback(formData)
						.then(() => {
							showNotification(
								'Success!',
								'Data has been saved Successfully',
								'success',
							);
							handleBackButtonClick();
							setLoading(false);
						})
						.catch((err) => {
							showNotification('Warning!', err, 'danger');
							setLoading(false);
						});
				} else if (result.dismiss === Swal.DismissReason.cancel) {
					Swal.fire('Cancelled', 'Your data is safe :)', 'error');
				}
			});
		} else {
			if (selectedSegment === null) {
				showNotification('Warning!', 'Please Entries Segment', 'danger');
			}
			if (selectedPIC === null) {
				showNotification('Warning!', 'Please Entries PIC', 'danger');
			}
			if (!values.comment) {
				showNotification('Warning!', 'Please Entries Comment', 'danger');
			}
		}
	};

	const handleBackButtonClick = () => {
		navigate('/transaction/outstanding');
	};

	return (
		<PageWrapper title={title.title}>
			<PageLayoutHeader />
			<Page container='fluid'>
				<Card stretch>
					<CardHeader borderSize={1}>
						<CardLabel>
							<CardTitle>
								<Icon
									icon='arrow-back'
									size='lg'
									className='me-1'
									style={{ cursor: 'pointer' }}
									onClick={() => handleBackButtonClick()}
								/>
								{t('outstanding')}
							</CardTitle>
						</CardLabel>
					</CardHeader>
					<CardBody>
						<Formik
							enableReinitialize
							initialValues={{ ...initialStateValues }}
							onSubmit={onSubmitFeedback}>
							{(formikField) => {
								return (
									<Form>
										<CardBody className='px-4'>
											<div className='row'>
												<div className='col-2'>
													<Label>From</Label>
												</div>
												<div className='col-10'>
													<Input
														disabled
														id='from'
														placeholder='from'
														value={
															`${detailEmail.from?.emailAddress.name}<${detailEmail.from?.emailAddress.address}>` ||
															''
														}
													/>
												</div>
											</div>
											<div className='row mt-2'>
												<div className='col-2'>
													<Label>Subject</Label>
												</div>
												<div className='col-10'>
													<Input
														disabled
														id='subject'
														placeholder='subject'
														value={detailEmail.subject || ''}
													/>
												</div>
											</div>
											<div className='row mt-2'>
												<div className='col-2'>
													<Label>Body Email</Label>
												</div>
												<div className='col-10'>
													<div
														style={{
															borderRadius: '1rem',
															backgroundColor: '#f8f9fa',
															padding: '0.5rem 1rem',
															paddingBottom: '50px',
															maxWidth: '1000px',
															overflowX: 'auto',
														}}>
														<iframe
															srcDoc={detailEmail.body?.content}
															width={`${window.innerWidth}px`}
															height={`${window.innerHeight - 660}px`}
															id='iframe-show'
															title='iframe-show'
														/>
													</div>
												</div>
											</div>
											<div className='row mt-2'>
												<div className='col-2'>
													<Label>Upload File</Label>
												</div>
												<div className='col-10'>
													<Input
														type='file'
														id='file'
														multiple
														onChange={(e) => handleUploadFile(e)}
													/>
												</div>
											</div>
											<div className='row mt-2'>
												<div className='col-2'>
													<Label>Change PIC</Label>
												</div>
												<div className='col-5'>
													<CustomAsyncSelect
														theme='info'
														ref={selectInputSegment}
														defaultOptions={optionSegment}
														loadOptions={loadOptionSegment}
														cacheOptions
														onChange={onChangeSegment}
														value={selectedSegment || ''}
														defaultValue={selectedSegment || ''}
														placeholder='Type Segment here ...'
													/>
												</div>
												<div className='col-5'>
													<CustomAsyncSelect
														theme='info'
														ref={selectInputPIC}
														defaultOptions={optionPIC}
														loadOptions={loadOptionsPICBySegment}
														cacheOptions
														onChange={onChangePIC}
														value={selectedPIC || ''}
														defaultValue={selectedPIC || ''}
														placeholder='Type PIC here ...'
													/>
												</div>
											</div>
											<div className='row mt-2'>
												<div className='col-2'>
													<Label>Comment</Label>
												</div>
												<div className='col-10'>
													<Textarea
														id='comment'
														onChange={formikField.handleChange}
														onBlur={formikField.handleBlur}
														value={formikField.values.comment || ''}
														isValid={formikField.isValid}
														isTouched={formikField.touched.comment}
														invalidFeedback={formikField.errors.comment}
														autoComplete='off'
														placeholder='Entries Comment'
													/>
												</div>
											</div>
										</CardBody>
										<CardFooter className='px-4 pb-4'>
											<div
												className='col-md-12 '
												style={{ display: 'block' }}>
												<Button
													style={{ marginLeft: '5px' }}
													onClick={() => {
														// setIsOpenFeedback(false);
													}}
													icon='Cancel'
													type='button'
													color='warning'
													className='float-start'>
													Cancel
												</Button>
												<Button
													icon='Save'
													type='submit'
													color='success'
													className='float-end'>
													Submit
												</Button>
											</div>
											<div
												className='col-md-12'
												style={{ display: loading ? 'block' : 'none' }}>
												<Label
													style={{ marginLeft: '5px', marginTop: '5px' }}
													className='float-end'>
													Processing saved data...
												</Label>
												<Spinner
													className='float-end'
													color='success'
													role='status'
													size='md'
												/>
											</div>
										</CardFooter>
									</Form>
								);
							}}
						</Formik>
					</CardBody>
				</Card>
			</Page>
		</PageWrapper>
	);
};

export default Main;
