import React from 'react';
import PropTypes from 'prop-types';
import Modal, { ModalBody } from '../bootstrap/Modal';
import Button from '../bootstrap/Button';
import Spinner from '../bootstrap/Spinner';

const LoadingModal = ({ loading, setLoading, onlyContent }) => {
	const renderComponent = (
		<Button color={onlyContent ? 'info' : 'primary'} isLight={onlyContent}>
			<Spinner isSmall inButton='onlyIcon' isGrow />
			&nbsp;
			<Spinner isSmall inButton='onlyIcon' isGrow />
			&nbsp;
			<Spinner isSmall inButton='onlyIcon' isGrow />
			&nbsp; Loading...
		</Button>
	);

	if (onlyContent) return renderComponent;

	return (
		<Modal
			isOpen={loading}
			size='sm'
			isCentered
			setIsOpen={setLoading}
			isStaticBackdrop
			zIndex={1061}>
			<ModalBody
				style={{ backgroundColor: '#6c5dd3', color: 'white' }}
				className='text-center'>
				{renderComponent}
			</ModalBody>
		</Modal>
	);
};

LoadingModal.propTypes = {
	loading: PropTypes.bool,
	setLoading: PropTypes.func,
	onlyContent: PropTypes.bool,
};
LoadingModal.defaultProps = {
	loading: false,
	setLoading: () => {},
	onlyContent: false,
};

export default LoadingModal;
