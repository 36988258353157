import Service from '../../services/sodiq/master-segment.service';

const create = (payload) => {
	return Service.create(payload).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

const read = (query_string) => {
	return Service.read(query_string).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

const readSelect = (query_string) => {
	return Service.readSelect(query_string).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

const readSelectNew = (query_string) => {
	return Service.readSelectNew(query_string).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

const readPIC = (query_string) => {
	return Service.readPIC(query_string).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};
const readPICOrg = (query_string) => {
	return Service.readPICOrg(query_string).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

const readOrganization = (query_string) => {
	return Service.readOrganization(query_string).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

const readOrganizationWithMasterSegment = (query_string) => {
	return Service.readOrganizationWithMasterSegment(query_string).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

const readOrganizationWithMasterSegmentNew = (query_string) => {
	return Service.readOrganizationWithMasterSegmentNew(query_string).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

export default {
	create,
	read,
	readSelect,
	readSelectNew,
	readPIC,
	readOrganization,
	readOrganizationWithMasterSegment,
	readOrganizationWithMasterSegmentNew,
	readPICOrg,
};
