import axios from 'axios';
import authHeader from '../auth-header';

const API_URL_DEFAULT = process.env.REACT_APP_API;
const APP_MODEL = 'outstanding';

const readDetail = async (query_string = '') => {
	return axios.get(`${API_URL_DEFAULT}${APP_MODEL}/detail?${query_string}`, {
		headers: await authHeader(),
	});
};

export default { readDetail };
