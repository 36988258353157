import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../../components/bootstrap/Modal';
import Label from '../../../components/bootstrap/forms/Label';
import QuillComponent from '../components/QuillComponent';
import Dropzone from '../components/Dropzone';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import Input from '../../../components/bootstrap/forms/Input';
import Select, { AsyncCreatableSelect, AsyncSelect } from '../components/Select';
import Button from '../../../components/bootstrap/Button';
import InputGroup, { InputGroupText } from '../../../components/bootstrap/forms/InputGroup';
import DatePicker from '../components/DatePicker';
import {
	copyUrlToClipboard,
	FormatDate,
	ListAssigne,
	ListReporter,
	ListTags,
	readFromLocalStorage,
	TimeAgo,
} from '../utils';
import FormText from '../../../components/bootstrap/forms/FormText';
import { getQueryParams } from '../../../helpers/helpers';
import TaskModule from '../../../modules/bakti/TaskModule';
import LoadingContent from '../components/LoadingContent';
import Alert from '../../../components/bootstrap/Alert';
import { formatBytes } from '../../../helpers/format';
import BoardModule from '../../../modules/bakti/BoardModule';
import OrganizationsModule from '../../../modules/OrganizationsModule';
import LoadingOverlay from '../components/LoadingOverlay';
import Actions from './Actions';

import USERS from '../../../common/data/userDummyData';
import Avatar from '../../../components/Avatar';

const AddCommentComponent = ({ taskCode, actor, onReload }) => {
	const [isFocus, setIsFocus] = useState(false);
	const [value, setValue] = useState('');

	const handleSave = () => {
		try {
			const newValue = {
				content: value,
				actor: JSON.stringify(actor),
			};

			Actions.createComment(taskCode, newValue)
				.then(() => {
					setIsFocus(false);
					setValue('');
					onReload();
				})
				.catch(() => {
					Swal.fire({
						heightAuto: false,
						title: 'Information!',
						text: 'Failed to save comment!',
						icon: 'error',
					});
				})
				.finally(() => {});
		} catch (err) {
			Swal.fire({
				heightAuto: false,
				title: 'Information!',
				text: 'Failed to save comment!',
				icon: 'error',
			});
		}
	};

	const handleCancel = () => {
		setIsFocus(false);
	};

	return (
		<div className='py-2 d-flex'>
			<div className='me-2'>
				<Avatar
					src={USERS.JOHN.src}
					srcSet={USERS.JOHN.srcSet}
					color={USERS.JOHN.color}
					size={36}
				/>
			</div>
			<div className='ms-2 flex-grow-1'>
				<QuillComponent
					useFocus
					value={value}
					onChange={setValue}
					isFocus={isFocus}
					setIsFocus={setIsFocus}
					textOnly
				/>

				{isFocus && (
					<div className='pt-2'>
						<Button
							color='info'
							type='button'
							size='sm'
							onClick={handleSave}
							className='mx-1'>
							Save
						</Button>

						<Button
							isLink
							color='info'
							type='button'
							size='sm'
							onClick={handleCancel}
							className='mx-1'>
							Cancel
						</Button>
					</div>
				)}
			</div>
		</div>
	);
};

AddCommentComponent.propTypes = {
	taskCode: PropTypes.string,
	actor: PropTypes.oneOfType([PropTypes.object]),
	onReload: PropTypes.func,
};
AddCommentComponent.defaultProps = {
	taskCode: null,
	actor: null,
	onReload: () => {},
};

const CommentComponent = ({ data, useFocus, taskCode, actor, onReload }) => {
	const [isEditable, setIsEditable] = useState(false);
	const [value, setValue] = useState(data.content);

	const handleSave = () => {
		try {
			const newValue = {
				content: value,
				actor: JSON.stringify(actor),
			};

			Actions.updateComment(data?._id, taskCode, newValue)
				.then(() => {
					setValue('');
					setIsEditable(false);
					onReload();
				})
				.catch(() => {
					Swal.fire({
						heightAuto: false,
						title: 'Information!',
						text: 'Failed to update comment!',
						icon: 'error',
					});
				})
				.finally(() => {});
		} catch (err) {
			Swal.fire({
				heightAuto: false,
				title: 'Information!',
				text: 'Failed to update comment!',
				icon: 'error',
			});
		}
	};

	const handleCancel = () => {
		setIsEditable(false);
	};

	const handleEdit = () => {
		setIsEditable(true);
	};

	const handleDelete = () => {
		try {
			const newValue = { actor: JSON.stringify(actor) };

			Actions.deleteComment(data?._id, taskCode, newValue)
				.then(() => {
					setValue('');
					setIsEditable(false);
					onReload();
				})
				.catch(() => {
					Swal.fire({
						heightAuto: false,
						title: 'Information!',
						text: 'Failed to delete comment!',
						icon: 'error',
					});
				})
				.finally(() => {});
		} catch (err) {
			Swal.fire({
				heightAuto: false,
				title: 'Information!',
				text: 'Failed to delete comment!',
				icon: 'error',
			});
		}
	};

	return (
		<div className='py-2 d-flex'>
			<div className='me-2'>
				<Avatar
					src={USERS.JOHN.src}
					srcSet={USERS.JOHN.srcSet}
					color={USERS.JOHN.color}
					size={36}
				/>
			</div>
			<div className='ms-2 row'>
				{!isEditable && (
					<div className='py-1 col-sm-12 d-flex align-items-center'>
						<div className='me-1 fw-bold'>{data?.actor?.name}</div>
						<div className='ms-1 form-text'>{TimeAgo(data?.created_at)}</div>
					</div>
				)}
				<div className='py-1 col-sm-12'>
					{/* edit */}
					{isEditable && (
						<div className='py-1'>
							<QuillComponent useFocus={useFocus} value={value} onChange={setValue} />
						</div>
					)}

					{/* view */}
					{!isEditable && (
						<div className='py-1'>
							<QuillComponent value={value} readOnly />
						</div>
					)}

					{isEditable && (
						<div className='pt-2'>
							<Button
								color='info'
								type='button'
								size='sm'
								onClick={handleSave}
								className='mx-1'>
								Save
							</Button>

							<Button
								isLink
								color='info'
								type='button'
								size='sm'
								onClick={handleCancel}
								className='mx-1'>
								Cancel
							</Button>
						</div>
					)}

					{!isEditable && (
						<div className='pt-2'>
							<Button
								isLink
								color='info'
								type='button'
								size='sm'
								onClick={handleEdit}
								className='mx-1'>
								Edit
							</Button>

							<Button
								isLink
								color='danger'
								type='button'
								size='sm'
								onClick={handleDelete}
								className='mx-1'>
								Delete
							</Button>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

CommentComponent.propTypes = {
	data: PropTypes.oneOfType([PropTypes.object]),
	useFocus: PropTypes.bool,
	taskCode: PropTypes.string,
	actor: PropTypes.oneOfType([PropTypes.object]),
	onReload: PropTypes.func,
};
CommentComponent.defaultProps = {
	data: null,
	useFocus: false,
	taskCode: null,
	actor: null,
	onReload: () => {},
};

const ActivitiesComponent = () => {
	const { task_code: taskCode } = getQueryParams();
	const currentUser = readFromLocalStorage('board_user');

	const [data, setData] = useState([]);
	const [options, setOptions] = useState({ loading: false, reload: true });

	useEffect(() => {
		const fetch = async () => {
			try {
				setOptions((prev) => ({ ...prev, loading: true }));

				TaskModule.findComment(taskCode, { perPage: 10 })
					.then((res) => {
						setData(res?.foundData || []);
					})
					.catch(() => {
						setData([]);
					})
					.finally(() => {
						setOptions((prev) => ({ ...prev, reload: false, loading: false }));
					});
			} catch (err) {
				//
			}
		};

		if (options.reload && taskCode) fetch();

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [options.reload]);

	return (
		<div>
			{/* add comment */}
			<AddCommentComponent
				taskCode={taskCode}
				actor={currentUser}
				onReload={() =>
					setTimeout(() => setOptions((prev) => ({ ...prev, reload: true })), 300)
				}
			/>

			<div className='border-bottom my-3' />

			{options.loading && <LoadingContent onlyContent />}

			{/* show comment 10 last created */}
			{!options.loading &&
				data.map((item, index) => (
					<CommentComponent
						key={'comment'.concat(index)}
						taskCode={taskCode}
						actor={currentUser}
						data={item}
						onReload={() =>
							setTimeout(() => setOptions((prev) => ({ ...prev, reload: true })), 300)
						}
					/>
				))}
		</div>
	);
};

ActivitiesComponent.propTypes = {};
ActivitiesComponent.defaultProps = {};

const LinkComponent = ({ value, onChange, onDelete, isEdit, isNew }) => {
	const [showEdit, setShowEdit] = useState(isEdit);

	const [data, setData] = useState(value);

	const handleSave = () => {
		onChange({ link: data, isEdit: false, isNew: false });
		setShowEdit(!showEdit);
	};

	const handleCancel = () => {
		if (isNew) {
			onDelete(true);
		} else {
			onChange({ link: value, isEdit: false, isNew: false }, true);
			setShowEdit(!showEdit);
		}
	};

	return (
		<div className='row py-1'>
			{!showEdit && (
				<div className='col-sm-12 d-flex'>
					<div className='flex-grow-1'>
						<Input value={value} readOnly />
					</div>
					<div>
						<Button
							isOutline
							icon='Edit'
							type='button'
							color='info'
							size='sm'
							onClick={() => setShowEdit(!showEdit)}
							className='mx-1'
						/>

						<Button
							isOutline
							icon='Delete'
							type='button'
							color='danger'
							size='sm'
							onClick={onDelete}
							className='mx-1'
						/>

						<Button
							isOutline
							icon='KeyboardArrowRight'
							type='button'
							color='success'
							size='sm'
							tag='a'
							href={value}
							target='_blank'
							onClick={() => {}}
							className='mx-1'
						/>
					</div>
				</div>
			)}

			{showEdit && (
				<div className='col-sm-12 py-1'>
					<InputGroup>
						<InputGroupText>URL</InputGroupText>
						<Input value={data} onChange={(e) => setData(e.target.value.trim())} />
					</InputGroup>
				</div>
			)}

			{showEdit && (
				<div className='col-sm-12 py-1'>
					<Button
						color='info'
						type='button'
						size='sm'
						isDisable={!data.trim()}
						onClick={handleSave}
						className='mx-1'>
						Save
					</Button>

					<Button
						isLink
						color='info'
						type='button'
						size='sm'
						onClick={handleCancel}
						className='mx-1'>
						Cancel
					</Button>
				</div>
			)}
		</div>
	);
};

LinkComponent.propTypes = {
	value: PropTypes.string,
	onChange: PropTypes.func,
	onDelete: PropTypes.func,
	isEdit: PropTypes.bool,
	isNew: PropTypes.bool,
};
LinkComponent.defaultProps = {
	value: '',
	onChange: () => '',
	onDelete: () => {},
	isEdit: true,
	isNew: true,
};

const ReferenceComponent = ({ data, onUpdate }) => {
	const [list, setList] = useState(data);

	const handleAdd = () => {
		setList([...list, { link: '', isEdit: true, isNew: true }]);
	};

	const handleChange = (index, value, isCancel) => {
		const newList = [...list];
		newList[index] = value;

		setList(newList);

		if (!isCancel) {
			const reference = newList.map((e) => e.link);
			onUpdate({ reference: JSON.stringify(reference) });
		}
	};

	const handleDelete = (index, isCancel) => {
		const newList = [...list];
		newList.splice(index, 1);

		setList(newList);

		if (!isCancel) {
			const reference = newList.map((e) => e.link);
			onUpdate({ reference: JSON.stringify(reference) });
		}
	};

	return (
		<div>
			<div className='py-1'>
				<Label className='fw-bold'>Reference</Label>
			</div>

			{list.map((item, index) => (
				<LinkComponent
					key={'link-'.concat(index)}
					value={item.link}
					isEdit={item.isEdit}
					isNew={item.isNew}
					onChange={(value, isCancel) => handleChange(index, value, isCancel)}
					onDelete={(isCancel) => handleDelete(index, isCancel)}
				/>
			))}
			<div className='py-1'>
				<Button
					isOutline
					icon='Add'
					color='info'
					type='button'
					onClick={handleAdd}
					className='mx-1'>
					Add Reference
				</Button>
			</div>
		</div>
	);
};

ReferenceComponent.propTypes = {
	data: PropTypes.oneOfType([PropTypes.array]),
	onUpdate: PropTypes.func,
};
ReferenceComponent.defaultProps = {
	data: [],
	onUpdate: () => {},
};

const InformationComponent = ({ data, onUpdate }) => {
	const { board_code: boardCode } = getQueryParams();
	const boardKey = readFromLocalStorage('board_key');
	const boardColumn = readFromLocalStorage('board_column');
	const listPriority = readFromLocalStorage('board_priority');

	const [showEdit, setShowEdit] = useState(false);
	const [loading, setLoading] = useState(false);

	const formik = useFormik({
		initialValues: {
			title: data?.title,
			priority: data?.priority,
			tags: data?.tags,
			status: data?.status,
			assignee: data?.assignee,
			reporter: data?.reporter,
			due_date: data?.duedate_at,
		},
		enableReinitialize: true,
		validationSchema: Yup.object({
			title: Yup.string().trim().required('Required'),
			priority: Yup.mixed().required('Required'),
			tags: Yup.array().optional(),
			status: Yup.mixed().required('Required'),
			assignee: Yup.array().optional(),
			reporter: Yup.object().nullable().required('Required'),
			due_date: Yup.date().nullable().optional(),
		}),
		onReset: () => {
			setShowEdit(false);
		},
		onSubmit: (v) => {
			try {
				const newValue = {
					title: v.title,
					priority: JSON.stringify(v.priority),
					tags: v.tags.length ? JSON.stringify(v.tags) : [],
					status: JSON.stringify(v.status),
					assignee: v.assignee.length ? JSON.stringify(v.assignee) : [],
					reporter: JSON.stringify(v.reporter),
					duedate_at: v.due_date,
				};

				setLoading(true);

				onUpdate(newValue, (err) => {
					if (err) {
						//
					} else {
						setShowEdit(false);
					}
					setLoading(false);
				});
			} catch (err) {
				Swal.fire({
					heightAuto: false,
					title: 'Information!',
					text: 'Please check your entries again!',
					icon: 'error',
				});
			}
		},
	});

	return (
		<Card shadow='sm'>
			<CardHeader size='sm' borderSize={1}>
				<CardLabel icon='InfoOutline' iconColor='info'>
					<CardTitle>Detail Information</CardTitle>
				</CardLabel>
				{!showEdit && (
					<CardActions>
						<Button
							isOutline
							icon='Edit'
							type='button'
							size='sm'
							color='info'
							onClick={() => setShowEdit(true)}
						/>
					</CardActions>
				)}
			</CardHeader>
			<CardBody>
				<div className='row py-1' style={{ minHeight: '45px' }}>
					<div className='col-sm-3 col-lg-3 fw-bold'>Code</div>
					<div className='col-sm-9 col-lg-9'>{`${boardKey}-${data?.task_key}`}</div>
				</div>

				<div className='row py-1' style={{ minHeight: '45px' }}>
					<div className='col-sm-3 col-lg-3 fw-bold'>Task Name</div>
					{!showEdit && <div className='col-sm-9 col-lg-9'>{formik.values.title}</div>}
					{showEdit && (
						<div className='col-sm-9 col-lg-9'>
							<Input
								name='title'
								autoComplete='off'
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.title}
								isValid={formik.isValid}
								isTouched={formik.touched.title}
								invalidFeedback={formik.errors.title}
							/>
						</div>
					)}
				</div>

				<div className='row py-1' style={{ minHeight: '45px' }}>
					<div className='col-sm-3 col-lg-3 fw-bold'>Priority </div>
					{!showEdit && (
						<div className='col-sm-9 col-lg-9'>{formik.values.priority?.label}</div>
					)}
					{showEdit && (
						<div className='col-sm-9 col-lg-9'>
							<Select
								placeholder='Select one'
								options={listPriority}
								defaultValue={formik.values.priority}
								value={formik.values.priority}
								onChange={(e) => formik.setFieldValue('priority', e)}
								isValid={!formik.errors.priority}
								isSubmitted={!!formik.errors.priority}
								invalidFeedback={formik.errors.priority}
							/>
						</div>
					)}
				</div>

				<div className='row py-1' style={{ minHeight: '45px' }}>
					<div className='col-sm-3 col-lg-3 fw-bold'>Tags </div>
					{!showEdit && (
						<div className='col-sm-9 col-lg-9'>{ListTags(formik.values.tags)}</div>
					)}
					{showEdit && (
						<div className='col-sm-9 col-lg-9'>
							<AsyncCreatableSelect
								// FIXME: need ref
								placeholder='Select Tags'
								loadOptions={(e) => OrganizationsModule.loadOptions(e)}
								cacheOptions
								defaultOptions
								defaultValue={formik.values.tags}
								value={formik.values.tags}
								onChange={(e) => formik.setFieldValue('tags', e)}
								isMulti
							/>
						</div>
					)}
				</div>

				<div className='row py-1' style={{ minHeight: '45px' }}>
					<div className='col-sm-3 col-lg-3 fw-bold'>Status </div>
					{!showEdit && (
						<div className='col-sm-9 col-lg-9'>{formik.values.status?.label}</div>
					)}
					{showEdit && (
						<div className='col-sm-9 col-lg-9'>
							<Select
								placeholder='Select One'
								options={boardColumn}
								defaultValue={formik.values.status}
								value={formik.values.status}
								onChange={(e) => formik.setFieldValue('status', e)}
								isValid={!formik.errors.status}
								isSubmitted={!!formik.errors.status}
								invalidFeedback={formik.errors.status}
							/>
						</div>
					)}
				</div>

				<div className='row py-1' style={{ minHeight: '45px' }}>
					<div className='col-sm-3 col-lg-3 fw-bold'>Assignee </div>
					{!showEdit && (
						<div className='col-sm-9 col-lg-9'>
							{ListAssigne(formik.values.assignee)}
						</div>
					)}
					{showEdit && (
						<div className='col-sm-9 col-lg-9'>
							<AsyncSelect
								placeholder='Type here..'
								loadOptions={() => BoardModule.loadOptionsMember(boardCode)}
								cacheOptions
								defaultOptions
								defaultValue={formik.values.assignee}
								value={formik.values.assignee}
								onChange={(e) => formik.setFieldValue('assignee', e)}
								isMulti
								isValid={!formik.errors.assignee}
								isSubmitted={!!formik.errors.assignee}
								invalidFeedback={formik.errors.assignee}
							/>
						</div>
					)}
				</div>

				<div className='row py-1' style={{ minHeight: '45px' }}>
					<div className='col-sm-3 col-lg-3 fw-bold'>Reporter (Owner) </div>
					{!showEdit && (
						<div className='col-sm-9 col-lg-9'>
							{ListReporter(formik.values.reporter)}
						</div>
					)}
					{showEdit && (
						<div className='col-sm-9 col-lg-9'>
							<AsyncSelect
								placeholder='Type here..'
								loadOptions={() => BoardModule.loadOptionsMember(boardCode)}
								cacheOptions
								defaultOptions
								defaultValue={formik.values.reporter}
								value={formik.values.reporter}
								onChange={(e) => formik.setFieldValue('reporter', e)}
								isValid={!formik.errors.reporter}
								isSubmitted={!!formik.errors.reporter}
								invalidFeedback={formik.errors.reporter}
							/>
						</div>
					)}
				</div>

				<div className='row py-1' style={{ minHeight: '45px' }}>
					<div className='col-sm-3 col-lg-3 fw-bold'>Due Date </div>
					{!showEdit && (
						<div className='col-sm-9 col-lg-9'>{FormatDate(data?.duedate_at)}</div>
					)}
					{showEdit && (
						<div className='col-sm-9 col-lg-9'>
							<DatePicker
								dateFormat='yyyy-MM-dd'
								placeholder='YYYY-MM-DD'
								minDate={new Date()}
								value={formik.values.due_date}
								onChange={(value) => formik.setFieldValue('due_date', value)}
								isSubmitted={!!formik.errors.due_date}
								invalidFeedback={formik.errors.due_date}
							/>
						</div>
					)}
				</div>

				<div className='row py-1' style={{ minHeight: '45px' }}>
					<div className='col-sm-3 col-lg-3 fw-bold'>Created At</div>
					<div className='col-sm-9 col-lg-9'>{FormatDate(data?.created_at, true)}</div>
				</div>

				<div className='row py-1' style={{ minHeight: '45px' }}>
					<div className='col-sm-3 col-lg-3 fw-bold'>Updated At</div>
					<div className='col-sm-9 col-lg-9'>{TimeAgo(data?.updated_at)}</div>
				</div>

				{showEdit && (
					<div className='py-1 d-flex justify-content-end'>
						<Button
							isLink
							color='info'
							type='button'
							size='sm'
							onClick={formik.resetForm}
							className='mx-1'>
							Cancel
						</Button>

						<Button
							color='info'
							type='button'
							size='sm'
							onClick={formik.handleSubmit}
							className='mx-1'>
							Save
						</Button>
					</div>
				)}

				<LoadingOverlay loading={loading} />
			</CardBody>
		</Card>
	);
};

InformationComponent.propTypes = {
	data: PropTypes.oneOfType([PropTypes.object]),
	onUpdate: PropTypes.func,
};
InformationComponent.defaultProps = {
	data: null,
	onUpdate: () => {},
};

const DescriptionComponents = ({ value, label, onUpdate }) => {
	const [data, setData] = useState(value);
	const [showEdit, setShowEdit] = useState(false);

	const handleSave = () => {
		onUpdate({ description: data }, (err) => {
			if (err) {
				setData(value);
			}
			setShowEdit(false);
		});
	};

	const handleCancel = () => {
		setData(value);
		setShowEdit(false);
	};

	return (
		<div>
			<div className='d-flex align-items-center py-1'>
				<div className='flex-grow-1'>
					<Label className='fw-bold'>{label}</Label>
				</div>

				{!showEdit && (
					<div>
						<Button
							isOutline
							icon='Edit'
							color='info'
							type='button'
							size='sm'
							className='mx-1'
							onClick={() => setShowEdit(true)}
						/>
					</div>
				)}
			</div>

			{!showEdit && (
				<div className='py-1'>
					<QuillComponent value={value} readOnly textOnly />
				</div>
			)}

			{showEdit && (
				<div className='py-1'>
					<QuillComponent value={data} onChange={setData} textOnly />
				</div>
			)}

			{showEdit && (
				<div className='py-1'>
					<Button
						color='info'
						type='button'
						size='sm'
						className='mx-1'
						style={{ zIndex: 1001, position: 'relative' }}
						onClick={handleSave}>
						Save
					</Button>

					<Button
						isLink
						color='info'
						type='button'
						size='sm'
						className='mx-1'
						onClick={handleCancel}>
						Cancel
					</Button>
				</div>
			)}
		</div>
	);
};

DescriptionComponents.propTypes = {
	value: PropTypes.string,
	onUpdate: PropTypes.func,
	label: PropTypes.string,
};
DescriptionComponents.defaultProps = {
	value: '',
	onUpdate: () => {},
	label: 'Label',
};

const FormUpdateTask = ({ isOpen, setIsOpen, title, size, id, onReload }) => {
	const { task_code: taskCode } = getQueryParams();

	// fileTypeTaskAttachment
	const fileType = readFromLocalStorage('task_attachment');
	const currentUser = readFromLocalStorage('board_user');

	const extentions = fileType?.extentions?.join(', ')?.toUpperCase();
	const limit = formatBytes(fileType?.limit);

	const [dataTask, setDataTask] = useState(null);
	const [loading, setLoading] = useState(false);

	const [isChange, setIsChange] = useState(false);

	useEffect(() => {
		const fetch = async () => {
			try {
				setLoading(true);

				TaskModule.findOneByTaskCode(taskCode)
					.then((response) => {
						const listReference = [...response.reference].map((e) => ({
							link: e,
							isEdit: false,
							isNew: false,
						}));
						setDataTask({ ...response, reference: listReference });
					})
					.catch(() => {
						setDataTask(null);
					})
					.finally(() => {
						setLoading(false);
					});
			} catch (err) {
				//
			}
		};

		if (isOpen) fetch();

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);

	const handleClose = () => {
		if (isChange) {
			setIsChange(false);
			onReload();
		}
		setIsOpen(false);
	};

	const handleUpdate = (payload, callback) => {
		Actions.updateTask(taskCode, { ...payload, actor: JSON.stringify(currentUser) })
			.then((response) => {
				const listReference = [...response.data.reference].map((e) => ({
					link: e,
					isEdit: false,
					isNew: false,
				}));
				const newData = { ...response.data, reference: listReference };
				setDataTask(newData);
				setIsChange(true);
				callback(null);
			})
			.catch((err) => {
				callback(err);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleTrash = () => {
		Swal.fire({
			heightAuto: false,
			title: 'Are you sure?',
			text: `You won't be able to revert this!`,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#00941b',
			confirmButtonText: 'Yes, Confirm',
			cancelButtonColor: '#d33',
			cancelButtonText: `No, Cancel`,
			reverseButtons: true,
		}).then((result) => {
			if (result.isConfirmed) {
				Actions.deleteTask(taskCode, { actor: JSON.stringify(currentUser) })
					.then(() => {
						Swal.fire({
							heightAuto: false,
							title: 'Success!',
							text: 'Task successfull moved to trash',
							icon: 'success',
						}).then(() => {
							onReload();
							setIsOpen(false);
						});
					})
					.catch(() => {})
					.finally(() => {});
			}
		});
	};

	return (
		<Modal
			setIsOpen={handleClose}
			isOpen={isOpen}
			titleId={id}
			size={size}
			isFocus={false}
			enableEscape={false}
			isStaticBackdrop>
			<ModalHeader setIsOpen={handleClose}>
				<ModalTitle id={id}>{title}</ModalTitle>
			</ModalHeader>
			<ModalBody>
				{loading && <LoadingContent onlyContent />}

				{!loading && dataTask && (
					<div className='row'>
						<div className='col-sm-12 col-lg-7'>
							<div className='row'>
								<div className='col-sm-12 py-1'>
									<DescriptionComponents
										label='Description'
										value={dataTask?.description}
										onUpdate={handleUpdate}
									/>
								</div>

								<div className='col-sm-12 border-bottom my-3' />

								<div className='col-sm-12 py-1'>
									<div className='py-1'>
										<Label className='fw-bold'>Attachment</Label>
									</div>
									<div className='mt-n3'>
										<FormText>
											({`Max size ${limit} with ${extentions} Extention`})
										</FormText>
									</div>
									<div className='py-1'>
										<Dropzone
											attachment={dataTask?.attachment}
											showAddButton
											mimeTypes={fileType.mimeTypes}
											maxLimit={fileType.limit}
											onUpdate={handleUpdate}
										/>
									</div>
								</div>

								<div className='col-sm-12 border-bottom my-3' />

								<div className='col-sm-12 py-1'>
									<ReferenceComponent
										data={dataTask?.reference}
										onUpdate={handleUpdate}
									/>
								</div>

								<div className='col-sm-12 py-1'>
									<div className='py-1'>
										<Label className='fw-bold'>Activity</Label>
									</div>
									<div className='py-1'>
										<ActivitiesComponent />
									</div>
								</div>
							</div>
						</div>

						<div className='col-sm-12 col-lg-5'>
							<div className='row'>
								<div className='col-sm-12'>
									<InformationComponent data={dataTask} onUpdate={handleUpdate} />
								</div>
							</div>
							<div className='row'>
								<div className='col-sm-12'>
									<Button
										isLight
										icon='ContentCopy'
										color='info'
										onClick={() => copyUrlToClipboard(true)}
										className='m-2'>
										Copy Link
									</Button>

									<Button
										isLight
										icon='ArrowRightAlt'
										color='info'
										onClick={() => {}}
										className='m-2'>
										Move Task
									</Button>

									<Button
										isLight
										icon='CalendarToday'
										color='success'
										onClick={() => {}}
										className='m-2'>
										Create Schedule
									</Button>

									<Button
										isLight
										icon='Delete'
										color='danger'
										onClick={handleTrash}
										className='m-2'>
										Move to Trash Bin
									</Button>
								</div>
							</div>
						</div>
					</div>
				)}

				{!loading && !dataTask && (
					<div className='w-100'>
						<Alert color='info' isLight icon='info'>
							No Data Found
						</Alert>
					</div>
				)}
			</ModalBody>
		</Modal>
	);
};

FormUpdateTask.propTypes = {
	id: PropTypes.string,
	title: PropTypes.string,
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
	size: PropTypes.oneOf([null, 'sm', 'lg', 'xl', 'xxl']),
	onReload: PropTypes.func,
};
FormUpdateTask.defaultProps = {
	id: 'formUpdateTask',
	title: 'Title',
	size: 'xl',
	onReload: () => {},
};

export default FormUpdateTask;
