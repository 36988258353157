import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import Swal from 'sweetalert2';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import Button from '../../../components/bootstrap/Button';
import PDFIcon from '../../../assets/img/custom-pdf.svg';
import DOCXIcon from '../../../assets/img/custom-docx.svg';
import XLSXIcon from '../../../assets/img/custom-xlsx.svg';
import IMGIcon from '../../../assets/img/custom-img.svg';
import LoadingIcon from '../../../assets/img/custom-loading.svg';
import './Dropzone.scss';
import Icon from '../../../components/icon/Icon';
import Popovers from '../../../components/bootstrap/Popovers';
import showNotification from '../../../components/extras/showNotification';
import { formatBytes } from '../../../helpers/format';
import FileModule from '../../../modules/FileModule';
import { base64ArrayToFileArray } from '../utils';
import { DOCDownloadObject, ImagePreview, PDFPreviewObject } from './FilePreview';

const Icons = {
	pdf: PDFIcon,
	docx: DOCXIcon,
	xlsx: XLSXIcon,
	jpg: IMGIcon,
	jpeg: IMGIcon,
	png: IMGIcon,
};

const PreviewComponent = () => {
	return <div>PreviewComponent</div>;
};

PreviewComponent.propTypes = {};
PreviewComponent.defaultProps = {};

const DropzoneComponent = ({
	attachment,
	showAddButton,
	isEditable,
	mimeTypes,
	maxLimit,
	onUpdate,
	isNew,
}) => {
	// {
	// 	data: null, // object file
	// 	file: null, // content from onedrive
	// 	content: null, // data preview
	// }
	const [data, setData] = useState([]);

	const [loading, setLoading] = useState(!isNew);

	const [content, setContent] = useState('');
	const [isModalOpen, setIsModalOpen] = useState(false);

	const { getRootProps, getInputProps, open } = useDropzone({
		noClick: true,
		noKeyboard: true,
		accept: mimeTypes.reduce((acc, mimetype) => ({ ...acc, [mimetype]: [] }), {}),

		onDrop: (acceptedFiles) => {
			// filter by size
			const filteredFiles = acceptedFiles.filter((f) => f.size <= maxLimit);

			if (filteredFiles.length < acceptedFiles.length) {
				const maxFormat = formatBytes(maxLimit);
				showNotification(
					'Information!',
					`Some files were too large and have been excluded. Max file size is ${maxFormat}`,
					'danger',
				);
			}

			// filter by duplicates and rename files
			const newFiles = filteredFiles
				.filter((f) => !data.some((e) => e?.data?.name === f.name))
				.map((f) => {
					const newName = f.name.toLowerCase().replace(/\s+/g, '-');
					const renamedFile = new File([f], newName, { type: f.type });

					const allowedThumbnail = ['image/png', 'image/jpeg'];

					// skip thumbnail
					if (allowedThumbnail.includes(f.type)) {
						// create thumbnail
						return {
							data: renamedFile,
							file: null,
							content: URL.createObjectURL(renamedFile),
						};
					}

					return { data: renamedFile, file: null, content: null };
				});

			// upload files here
			if (newFiles.length) {
				const formData = new FormData();
				newFiles.forEach((item) => {
					formData.append('files', item.data);
				});

				const copyFiles = [...data];

				setLoading(true);

				FileModule.postFiles(formData)
					.then((response) => {
						const _newFiles = [...response].map((file, index) => {
							const fileName = file.originalname.split('-').slice(2).join('-');

							return {
								content: newFiles[index].content,
								data: newFiles[index].data,
								file: {
									onedrive_id: file.onedrive_id,
									originalname: fileName,
									mimetype: file.mimetype,
									created_at: file.created_at,
									is_active: file.is_active,
								},
							};
						});

						const allFiles = [...data, ..._newFiles];

						setData(allFiles);

						const uploadFiles = allFiles.map((item) => item.file);

						onUpdate({ attachment: JSON.stringify(uploadFiles) }, (err) => {
							if (err) {
								// restore view
								setData(copyFiles);

								Swal.fire({
									heightAuto: false,
									title: 'Information!',
									text: 'Failed upload file',
									icon: 'error',
								});
							}
						});
					})
					.catch(() => {
						// restore view
						setData(copyFiles);

						Swal.fire({
							heightAuto: false,
							title: 'Information!',
							text: 'Failed upload file',
							icon: 'error',
						});
					})
					.finally(() => {
						setLoading(false);
					});
			}

			// append new files to existing files
			const alldata = [...data, ...newFiles];
			setData(alldata);
		},
	});

	useEffect(() => {
		const fetch = async () => {
			try {
				setLoading(true);

				const newFiles = attachment.map((e) => ({
					onedrive_id: e.onedrive_id,
					filename: e.originalname,
				}));

				FileModule.getFiles({ files: JSON.stringify(newFiles) })
					.then((response) => {
						const newData = base64ArrayToFileArray(response, attachment);

						setData(newData);
					})
					.catch(() => {
						setData([]);
					})
					.finally(() => {
						setLoading(false);
					});
			} catch (err) {
				setLoading(false);
			}
		};

		if (attachment?.length) {
			fetch();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onRemove = (index) => {
		const updatedData = data.filter((_, _index) => _index !== index);

		setData(updatedData);

		onUpdate({ attachment: JSON.stringify(updatedData) }, (err) => {
			if (err) {
				// restore view
				setData(data);

				Swal.fire({
					heightAuto: false,
					title: 'Information!',
					text: 'Failed upload file',
					icon: 'error',
				});
			}
		});

		// onChange here
	};

	const onPreview = (index, item) => {
		// load by preview
		if (item?.data?.preview) {
			setContent(item.data.preview);
			setIsModalOpen(true);
		}

		// load by thumbnails
		else if (item?.content) {
			setContent(item.content);
			setIsModalOpen(true);
		}

		// open new tab browser for pdf
		else if (item?.data?.type === 'application/pdf') {
			PDFPreviewObject(item?.data);
		}

		// download for docx or xlsx
		else {
			DOCDownloadObject(item?.data);
		}
	};

	const onThumbnail = (index, item) => {
		// load by thumbnails

		if (item?.content) return item.content;

		// load document
		const extention = item?.data?.name?.split('.')?.pop()?.toLowerCase();
		if (extention) return Icons[extention];

		// default
		return Icons.jpeg;
	};

	return (
		<div>
			{showAddButton && isEditable && (
				<div className='py-1'>
					<Button
						isOutline
						icon='Attachment'
						color='info'
						type='button'
						isDisable={loading}
						onClick={open}
						className='mx-1'>
						Add Attachment
					</Button>
				</div>
			)}

			{!isEmpty(data) && (
				<section className='container-box'>
					{/* DROP AREA */}
					{!showAddButton && isEditable && (
						<div
							{...getRootProps({ className: 'dropzone' })}
							className='dropzone dropzone-style'>
							<input {...getInputProps()} />
							<p>Drag 'n' drop some files here, or click to select files</p>
						</div>
					)}

					{/* PREVIEW */}
					<aside className='dropzone thumbs-container'>
						{data.map((item, index) => (
							<div
								className='dropzone thumb'
								key={item?.data?.name || 'files-'.concat(index)}>
								{loading && (
									<button
										type='button'
										className='dropzone thumb-inner rounded-2 border-0'>
										<Popovers desc={item?.data?.name} trigger='hover'>
											<img
												src={LoadingIcon}
												className='dropzone img-preview'
												alt='img-preview'
											/>
										</Popovers>
									</button>
								)}

								{!loading && isEditable && (
									<button
										type='button'
										onClick={() => onRemove(index)}
										className='dropzone delete-button'>
										<Icon icon='Delete' color='danger' size='2x' />
									</button>
								)}

								{!loading && (
									<Popovers desc={item?.data?.name} trigger='hover'>
										<button
											type='button'
											onClick={() => onPreview(index, item)}
											className='dropzone thumb-inner rounded-2 border-0'>
											<img
												src={onThumbnail(index, item)}
												className='dropzone img-preview'
												alt='img-preview'
											/>
										</button>
									</Popovers>
								)}
							</div>
						))}
					</aside>
				</section>
			)}

			<ImagePreview img={content} isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
		</div>
	);
};

DropzoneComponent.propTypes = {
	showAddButton: PropTypes.bool,
	isEditable: PropTypes.bool,
	attachment: PropTypes.oneOfType([PropTypes.array]),
	mimeTypes: PropTypes.oneOfType([PropTypes.array]),
	maxLimit: PropTypes.number,
	onUpdate: PropTypes.func,
	isNew: PropTypes.bool,
};
DropzoneComponent.defaultProps = {
	showAddButton: false,
	isEditable: true,
	attachment: [],
	mimeTypes: ['image/jpeg', 'image/png', 'application/pdf'],
	maxLimit: 2097152,
	onUpdate: () => {},
	isNew: false,
};

export default DropzoneComponent;
