import Service from '../services/general-setting.service';

const getFileTypeAndLimit = (query) => {
	return Service.getFileTypeAndLimit(query).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();

			return Promise.reject(message);
		},
	);
};

export default { getFileTypeAndLimit };
