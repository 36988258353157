import Service from '../../services/bakti/board.service';

const index = {};

index.findOneByBoardCode = (boardCode) => {
	return Service.findOneByBoardCode(boardCode).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.message && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

index.findDescriptionByBoardCode = (boardCode) => {
	return Service.findDescriptionByBoardCode(boardCode).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.message && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

index.findMemberByBoardCode = (boardCode) => {
	return Service.findMemberByBoardCode(boardCode).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.message && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

index.findColumnByBoardCode = (boardCode) => {
	return Service.findColumnByBoardCode(boardCode).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.message && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

index.findActivityByBoardCode = (boardCode) => {
	return Service.findActivityByBoardCode(boardCode).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.message && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

index.updateOneByBoardCode = (boardCode, payload) => {
	return Service.updateOneByBoardCode(boardCode, payload).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.message && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

index.closeOneByBoardCode = (boardCode, payload) => {
	return Service.closeOneByBoardCode(boardCode, payload).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.message && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

index.leaveOneByBoardCode = (boardCode, payload) => {
	return Service.leaveOneByBoardCode(boardCode, payload).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.message && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

index.loadOptionsColumn = (boardCode) => {
	return new Promise((resolve) => {
		Service.findColumnByBoardCode(boardCode)
			.then((response) => {
				resolve(response.data);
			})
			.catch(() => {
				resolve([]);
			});
	});
};

index.loadOptionsMember = (boardCode) => {
	return new Promise((resolve) => {
		Service.findMemberByBoardCode(boardCode)
			.then((response) => {
				const list = [...response.data.members].map((user) => ({
					username: user?.username,
					name: user?.name,
					email: user?.email,
					role: user?.role,
					value: user?.username,
					label: `${user?.username} - ${user?.name}`,
				}));
				resolve(list);
			})
			.catch(() => {
				resolve([]);
			})
			.finally(() => {});
	});
};

export default index;
