import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './QuillComponent.scss';
import Input from '../../../components/bootstrap/forms/Input';

const QuillComponent = ({ value, onChange, readOnly, textOnly, useFocus, isFocus, setIsFocus }) => {
	const optionsFormats = [];
	const optionsToolbar = [];

	const quillRef = useRef(null);

	if (!textOnly) {
		optionsFormats.push('link', 'image');
		optionsToolbar.push(['link', 'image'], ['clean']);
	}

	const handleQuillBlur = () => {
		if (useFocus && !readOnly) setIsFocus(false);
	};

	const handleInputFocus = () => {
		if (useFocus && !readOnly) setIsFocus(true);
	};

	useEffect(() => {
		if (isFocus && quillRef.current) {
			const editor = quillRef.current.getEditor();
			editor.focus();
		}
	}, [isFocus]);

	return (
		<div>
			{isFocus || !useFocus || readOnly ? (
				<ReactQuill
					ref={quillRef}
					theme='snow'
					value={value}
					onChange={onChange}
					modules={{
						toolbar: readOnly
							? false
							: [
									[{ header: [1, 2, false] }],
									['bold', 'italic', 'underline', 'strike', 'blockquote'],
									[
										{ list: 'ordered' },
										{ list: 'bullet' },
										{ indent: '-1' },
										{ indent: '+1' },
									],
									...optionsToolbar,
							  ],
					}}
					formats={[
						'header',
						'bold',
						'italic',
						'underline',
						'strike',
						'blockquote',
						'list',
						'bullet',
						'indent',
						...optionsFormats,
					]}
					onBlur={handleQuillBlur}
					readOnly={readOnly}
					className={readOnly ? 'custom-editor read-only' : 'custom-editor'}
					// style={{ minHeight: '60px', height: readOnly && '60px' }}
					placeholder='Write a comment...'
				/>
			) : (
				<Input
					type='text'
					onFocus={handleInputFocus}
					readOnly
					placeholder='Write a comment...'
				/>
			)}
		</div>
	);
};

QuillComponent.propTypes = {
	value: PropTypes.string,
	onChange: PropTypes.func,
	readOnly: PropTypes.bool,
	textOnly: PropTypes.bool,
	useFocus: PropTypes.bool,
	isFocus: PropTypes.bool,
	setIsFocus: PropTypes.func,
};
QuillComponent.defaultProps = {
	value: '',
	onChange: () => '',
	readOnly: false,
	textOnly: false,
	useFocus: false,
	isFocus: false,
	setIsFocus: () => {},
};

export default QuillComponent;
