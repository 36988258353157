import React, { forwardRef } from 'react';
import ReactSelect from 'react-select';
import ReactAsyncSelect from 'react-select/async';
import ReactAsyncCreatableSelect from 'react-select/async-creatable';
import PropTypes from 'prop-types';
import COLORS from '../../../common/data/enumColors';
import useDarkMode from '../../../hooks/useDarkMode';

// custom colors
const _COLORS = {
	dark: {
		color: '#E7EEF8',
		bgColor: '#212529',
		borderColor: '#343A40',
		disable: {
			borderColor: '#343A40',
			bgColor: '#343A40',
		},
		menu: {
			color: '#E7EEF8',
			bgColor: '#212529',
		},
		multi: {
			bgColor: '#3C3C3C',
		},
		multiRemove: {
			bgColor: '#6C6C6C',
		},
	},
	light: {
		color: '#323232',
		bgColor: '#F8F9FA',
		borderColor: '#E9ECEF',
		disable: {
			borderColor: '#E7EEF8',
			bgColor: '#E9ECEF',
		},
		menu: {
			color: '#323232',
			bgColor: '#F8F9Fa',
		},
		multi: {
			bgColor: '#DFDFDF',
		},
		multiRemove: {
			bgColor: '#C8C8C8',
		},
	},
	theme: {
		primary50: '#9A93CF',
		primary25: '#8176CF',
		primary: '#6C5DD3',
	},
};

const themeSelect = (theme) => ({
	...theme,
	colors: {
		...theme.colors,
		primary50: _COLORS.theme.primary50,
		primary25: _COLORS.theme.primary25,
		primary: _COLORS.theme.primary,
	},
});

const controlBorderColor = (darkModeStatus, valid, disable, focus) => {
	if (disable)
		return darkModeStatus
			? _COLORS.dark.disable.borderColor
			: _COLORS.light.disable.borderColor;
	if (valid || focus)
		return darkModeStatus ? _COLORS.dark.borderColor : _COLORS.light.borderColor;
	return COLORS.DANGER.code;
};

const controlBackgroundColor = (darkModeStatus, valid, disable) => {
	if (disable)
		return darkModeStatus ? _COLORS.dark.disable.bgColor : _COLORS.light.disable.bgColor;
	return darkModeStatus ? _COLORS.dark.bgColor : _COLORS.light.bgColor;
};

export const AsyncCreatableSelect = forwardRef(
	({ themes, invalidFeedback, isSubmitted, styles, isValid, ...props }, ref) => {
		const { darkModeStatus } = useDarkMode();

		// modified theme hover
		const theme_select = (theme) => ({
			...theme,
			colors: {
				...theme.colors,
				primary50: _COLORS.theme.primary50,
				primary25: _COLORS.theme.primary25,
				primary: _COLORS.theme.primary,
			},
		});

		// border color
		const control_border_color = (valid, disable, focus) => {
			if (disable)
				return darkModeStatus
					? _COLORS.dark.disable.borderColor
					: _COLORS.light.disable.borderColor;
			if (valid || focus)
				return darkModeStatus ? _COLORS.dark.borderColor : _COLORS.light.borderColor;
			return COLORS.DANGER.code;
		};

		const control_background_color = (valid, disable) => {
			if (disable)
				return darkModeStatus
					? _COLORS.dark.disable.bgColor
					: _COLORS.light.disable.bgColor;
			return darkModeStatus ? _COLORS.dark.bgColor : _COLORS.light.bgColor;
		};

		// modified style
		const custom_style = {
			...styles,
			clearIndicator: (base) => ({
				...base,
			}),
			container: (base) => ({
				...base,
			}),
			// main
			control: (base, { isDisabled, isFocused }) => ({
				...base,
				borderRadius: 'var(--bs-border-radius) !important',
				color: darkModeStatus ? _COLORS.dark.color : _COLORS.light.color,
				backgroundColor: control_background_color(isValid, isDisabled),
				borderColor: control_border_color(isValid, isDisabled, isFocused),
			}),
			// button dropdown
			dropdownIndicator: (base) => ({
				...base,
			}),
			group: (base) => ({
				...base,
			}),
			groupHeading: (base) => ({
				...base,
			}),
			indicatorsContainer: (base) => ({
				...base,
			}),
			indicatorSeparator: (base) => ({
				...base,
			}),
			input: (base) => ({
				...base,
				color: darkModeStatus ? _COLORS.dark.color : _COLORS.light.color,
			}),
			loadingIndicator: (base) => ({
				...base,
			}),
			loadingMessage: (base) => ({
				...base,
			}),
			// menu dropdown color
			menu: (base) => ({
				...base,
				zIndex: 1020,
				borderRadius: 'var(--bs-border-radius) !important',
				color: darkModeStatus ? _COLORS.dark.menu.color : _COLORS.light.menu.color,
				backgroundColor: darkModeStatus
					? _COLORS.dark.menu.bgColor
					: _COLORS.light.menu.bgColor,
			}),
			// menu dropdown label color
			menuList: (base) => ({
				...base,
				borderRadius: 'var(--bs-border-radius) !important',
				zIndex: 1020,
			}),
			menuPortal: (base) => ({
				...base,
				borderRadius: 'var(--bs-border-radius) !important',
				color: 'red',
				zIndex: 1020,
			}),
			// multivalue list
			multiValue: (base) => ({
				...base,
			}),
			// multivalue list label
			multiValueLabel: (base) => ({
				...base,
				borderRadius: '0px',
				color: darkModeStatus ? _COLORS.dark.color : _COLORS.light.color,
				backgroundColor: darkModeStatus
					? _COLORS.dark.multi.bgColor
					: _COLORS.light.multi.bgColor,
			}),
			// multivalue list button remove
			multiValueRemove: (base) => ({
				...base,
				borderRadius: '0px',
				color: darkModeStatus ? _COLORS.dark.color : _COLORS.light.color,
				backgroundColor: darkModeStatus
					? _COLORS.dark.multiRemove.bgColor
					: _COLORS.light.multiRemove.bgColor,
			}),
			noOptionsMessage: (base) => ({
				...base,
			}),
			option: (base, { isDisabled }) => ({
				...base,
				cursor: isDisabled ? 'not-allowed' : 'default',
			}),
			placeholder: (base) => ({
				...base,
			}),
			// color label value
			singleValue: (base) => ({
				...base,
				color: darkModeStatus ? _COLORS.dark.color : _COLORS.light.color,
			}),
			valueContainer: (base) => ({
				...base,
			}),
		};

		return (
			<div>
				<ReactAsyncCreatableSelect
					ref={ref}
					cacheOptions
					defaultOptions
					styles={custom_style}
					theme={themes ?? theme_select}
					{...props}
				/>

				{invalidFeedback && isSubmitted && (
					<div style={{ color: '#f35421', fontSize: '0.875rem' }} className='mt-1'>
						{invalidFeedback}
					</div>
				)}
			</div>
		);
	},
);

AsyncCreatableSelect.propTypes = {
	styles: PropTypes.instanceOf(Object),
	invalidFeedback: PropTypes.string,
	themes: PropTypes.instanceOf(Object),
	isSubmitted: PropTypes.bool,
	isValid: PropTypes.bool,
};
AsyncCreatableSelect.defaultProps = {
	styles: null,
	invalidFeedback: null,
	themes: null,
	isSubmitted: false,
	isValid: true,
};

export const AsyncSelect = forwardRef(
	({ themes, invalidFeedback, isSubmitted, styles, isValid, ...props }, ref) => {
		const { darkModeStatus } = useDarkMode();

		// modified theme hover
		const theme_select = (theme) => ({
			...theme,
			colors: {
				...theme.colors,
				primary50: _COLORS.theme.primary50,
				primary25: _COLORS.theme.primary25,
				primary: _COLORS.theme.primary,
			},
		});

		// border color
		const control_border_color = (valid, disable, focus) => {
			if (disable)
				return darkModeStatus
					? _COLORS.dark.disable.borderColor
					: _COLORS.light.disable.borderColor;
			if (valid || focus)
				return darkModeStatus ? _COLORS.dark.borderColor : _COLORS.light.borderColor;
			return COLORS.DANGER.code;
		};

		const control_background_color = (valid, disable) => {
			if (disable)
				return darkModeStatus
					? _COLORS.dark.disable.bgColor
					: _COLORS.light.disable.bgColor;
			return darkModeStatus ? _COLORS.dark.bgColor : _COLORS.light.bgColor;
		};

		// modified style
		const custom_style = {
			...styles,
			clearIndicator: (base) => ({
				...base,
			}),
			container: (base) => ({
				...base,
			}),
			// main
			control: (base, { isDisabled, isFocused }) => ({
				...base,
				borderRadius: 'var(--bs-border-radius) !important',
				color: darkModeStatus ? _COLORS.dark.color : _COLORS.light.color,
				backgroundColor: control_background_color(isValid, isDisabled),
				borderColor: control_border_color(isValid, isDisabled, isFocused),
			}),
			// button dropdown
			dropdownIndicator: (base) => ({
				...base,
			}),
			group: (base) => ({
				...base,
			}),
			groupHeading: (base) => ({
				...base,
			}),
			indicatorsContainer: (base) => ({
				...base,
			}),
			indicatorSeparator: (base) => ({
				...base,
			}),
			input: (base) => ({
				...base,
				color: darkModeStatus ? _COLORS.dark.color : _COLORS.light.color,
			}),
			loadingIndicator: (base) => ({
				...base,
			}),
			loadingMessage: (base) => ({
				...base,
			}),
			// menu dropdown color
			menu: (base) => ({
				...base,
				zIndex: 1020,
				borderRadius: 'var(--bs-border-radius) !important',
				color: darkModeStatus ? _COLORS.dark.menu.color : _COLORS.light.menu.color,
				backgroundColor: darkModeStatus
					? _COLORS.dark.menu.bgColor
					: _COLORS.light.menu.bgColor,
			}),
			// menu dropdown label color
			menuList: (base) => ({
				...base,
				borderRadius: 'var(--bs-border-radius) !important',
				zIndex: 1020,
			}),
			menuPortal: (base) => ({
				...base,
				borderRadius: 'var(--bs-border-radius) !important',
				color: 'red',
				zIndex: 1020,
			}),
			// multivalue list
			multiValue: (base) => ({
				...base,
			}),
			// multivalue list label
			multiValueLabel: (base) => ({
				...base,
				borderRadius: '0px',
				color: darkModeStatus ? _COLORS.dark.color : _COLORS.light.color,
				backgroundColor: darkModeStatus
					? _COLORS.dark.multi.bgColor
					: _COLORS.light.multi.bgColor,
			}),
			// multivalue list button remove
			multiValueRemove: (base) => ({
				...base,
				borderRadius: '0px',
				color: darkModeStatus ? _COLORS.dark.color : _COLORS.light.color,
				backgroundColor: darkModeStatus
					? _COLORS.dark.multiRemove.bgColor
					: _COLORS.light.multiRemove.bgColor,
			}),
			noOptionsMessage: (base) => ({
				...base,
			}),
			option: (base, { isDisabled }) => ({
				...base,
				cursor: isDisabled ? 'not-allowed' : 'default',
			}),
			placeholder: (base) => ({
				...base,
			}),
			// color label value
			singleValue: (base) => ({
				...base,
				color: darkModeStatus ? _COLORS.dark.color : _COLORS.light.color,
			}),
			valueContainer: (base) => ({
				...base,
			}),
		};

		return (
			<>
				<ReactAsyncSelect
					ref={ref}
					cacheOptions
					defaultOptions
					styles={custom_style}
					theme={themes ?? theme_select}
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...props}
				/>
				{/* invalid component */}
				{invalidFeedback && isSubmitted && (
					<div style={{ color: '#f35421', fontSize: '0.875rem' }} className='mt-1'>
						{invalidFeedback}
					</div>
				)}
			</>
		);
	},
);

AsyncSelect.propTypes = {
	styles: PropTypes.instanceOf(Object),
	invalidFeedback: PropTypes.string,
	themes: PropTypes.instanceOf(Object),
	isSubmitted: PropTypes.bool,
	isValid: PropTypes.bool,
};
AsyncSelect.defaultProps = {
	styles: null,
	invalidFeedback: null,
	themes: null,
	isSubmitted: false,
	isValid: true,
};

const Select = ({ themes, invalidFeedback, isSubmitted, styles, isValid, ...props }) => {
	const { darkModeStatus } = useDarkMode();

	// modified style
	const custom_style = {
		...styles,
		clearIndicator: (base) => ({
			...base,
		}),
		container: (base) => ({
			...base,
		}),
		// main
		control: (base, { isDisabled, isFocused }) => ({
			...base,
			borderRadius: 'var(--bs-border-radius) !important',
			color: darkModeStatus ? _COLORS.dark.color : _COLORS.light.color,
			backgroundColor: controlBackgroundColor(darkModeStatus, isValid, isDisabled),
			borderColor: controlBorderColor(darkModeStatus, isValid, isDisabled, isFocused),
		}),
		// button dropdown
		dropdownIndicator: (base) => ({
			...base,
		}),
		group: (base) => ({
			...base,
		}),
		groupHeading: (base) => ({
			...base,
		}),
		indicatorsContainer: (base) => ({
			...base,
		}),
		indicatorSeparator: (base) => ({
			...base,
		}),
		input: (base) => ({
			...base,
			color: darkModeStatus ? _COLORS.dark.color : _COLORS.light.color,
		}),
		loadingIndicator: (base) => ({
			...base,
		}),
		loadingMessage: (base) => ({
			...base,
		}),
		// menu dropdown color
		menu: (base) => ({
			...base,
			borderRadius: 'var(--bs-border-radius) !important',
			color: darkModeStatus ? _COLORS.dark.menu.color : _COLORS.light.menu.color,
			backgroundColor: darkModeStatus
				? _COLORS.dark.menu.bgColor
				: _COLORS.light.menu.bgColor,
		}),
		// menu dropdown label color
		menuList: (base) => ({
			...base,
			borderRadius: 'var(--bs-border-radius) !important',
		}),
		menuPortal: (base) => ({
			...base,
			borderRadius: 'var(--bs-border-radius) !important',
			color: 'red',
			zIndex: 9999,
		}),
		// multivalue list
		multiValue: (base) => ({
			...base,
		}),
		// multivalue list label
		multiValueLabel: (base) => ({
			...base,
			borderRadius: '0px',
			color: darkModeStatus ? _COLORS.dark.color : _COLORS.light.color,
			backgroundColor: darkModeStatus
				? _COLORS.dark.multi.bgColor
				: _COLORS.light.multi.bgColor,
		}),
		// multivalue list button remove
		multiValueRemove: (base) => ({
			...base,
			borderRadius: '0px',
			color: darkModeStatus ? _COLORS.dark.color : _COLORS.light.color,
			backgroundColor: darkModeStatus
				? _COLORS.dark.multiRemove.bgColor
				: _COLORS.light.multiRemove.bgColor,
		}),
		noOptionsMessage: (base) => ({
			...base,
		}),
		option: (base, { isDisabled }) => ({
			...base,
			cursor: isDisabled ? 'not-allowed' : 'default',
		}),
		placeholder: (base) => ({
			...base,
		}),
		// color label value
		singleValue: (base) => ({
			...base,
			color: darkModeStatus ? _COLORS.dark.color : _COLORS.light.color,
		}),
		valueContainer: (base) => ({
			...base,
		}),
	};

	return (
		<>
			<ReactSelect
				styles={custom_style}
				theme={themes ?? themeSelect}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...props}
			/>
			{/* invalid component */}
			{invalidFeedback && isSubmitted && (
				<div style={{ color: '#f35421', fontSize: '0.875rem' }} className='mt-1'>
					{invalidFeedback}
				</div>
			)}
		</>
	);
};

Select.propTypes = {
	styles: PropTypes.instanceOf(Object),
	invalidFeedback: PropTypes.string,
	themes: PropTypes.instanceOf(Object),
	isSubmitted: PropTypes.bool,
	isValid: PropTypes.bool,
};
Select.defaultProps = {
	styles: null,
	invalidFeedback: null,
	themes: null,
	isSubmitted: false,
	isValid: true,
};

export default Select;
