import React from 'react';
import ReactSelect from 'react-select';
import ReactAsyncSelect from 'react-select/async';
import ReactAsyncCreatableSelect from 'react-select/async-creatable';
import ReactCreatableSelect from 'react-select/creatable';
import PropTypes from 'prop-types';
import animated from 'react-select/animated';
import COLORS from '../../common/data/enumColors';
import useDarkMode from '../../hooks/useDarkMode';

// custom colors
const _COLORS = {
	dark: {
		color: '#E7EEF8',
		bgColor: '#212529',
		borderColor: '#343A40',
		disable: {
			borderColor: '#343A40',
			bgColor: '#343A40',
		},
		menu: {
			color: '#E7EEF8',
			bgColor: '#212529',
		},
		multi: {
			bgColor: '#3C3C3C',
		},
		multiRemove: {
			bgColor: '#6C6C6C',
		},
	},
	light: {
		color: '#323232',
		bgColor: '#F8F9FA',
		borderColor: '#E9ECEF',
		disable: {
			borderColor: '#E7EEF8',
			bgColor: '#E9ECEF',
		},
		menu: {
			color: '#323232',
			bgColor: '#F8F9Fa',
		},
		multi: {
			bgColor: '#DFDFDF',
		},
		multiRemove: {
			bgColor: '#C8C8C8',
		},
	},
	theme: {
		primary50: '#9A93CF',
		primary25: '#8176CF',
		primary: '#6C5DD3',
	},
};

const themeSelect = (theme) => ({
	...theme,
	colors: {
		...theme.colors,
		primary50: _COLORS.theme.primary50,
		primary25: _COLORS.theme.primary25,
		primary: _COLORS.theme.primary,
	},
});

const controlBorderColor = (darkModeStatus, valid, disable, focus) => {
	if (disable)
		return darkModeStatus
			? _COLORS.dark.disable.borderColor
			: _COLORS.light.disable.borderColor;
	if (valid || focus)
		return darkModeStatus ? _COLORS.dark.borderColor : _COLORS.light.borderColor;
	return COLORS.DANGER.code;
};

const controlBackgroundColor = (darkModeStatus, valid, disable) => {
	if (disable)
		return darkModeStatus ? _COLORS.dark.disable.bgColor : _COLORS.light.disable.bgColor;
	return darkModeStatus ? _COLORS.dark.bgColor : _COLORS.light.bgColor;
};

export const AsyncCreatableSelect = ({
	id,
	name,
	className,
	placeholder,
	components,
	styles,
	themes,
	isMulti,
	isLoading,
	isClearable,
	isDisable,
	isValid,
	defaultOptions,
	loadOptions,
	invalidFeedback,
	validFeedback,
	value,
	defaultValue,
	options,
	onBlur,
	onChange,
	onFocus,
	onInput,
	onInvalid,
	onSelect,
	...props
}) => {
	const { darkModeStatus } = useDarkMode();

	// modified style
	const custom_style = {
		...styles,
		clearIndicator: (base) => ({
			...base,
		}),
		container: (base) => ({
			...base,
		}),
		// main
		control: (base, { isDisabled, isFocused }) => ({
			...base,
			color: darkModeStatus ? _COLORS.dark.color : _COLORS.light.color,
			backgroundColor: controlBackgroundColor(darkModeStatus, isValid, isDisabled),
			borderColor: controlBorderColor(darkModeStatus, isValid, isDisabled, isFocused),
		}),
		// button dropdown
		dropdownIndicator: (base) => ({
			...base,
		}),
		group: (base) => ({
			...base,
		}),
		groupHeading: (base) => ({
			...base,
		}),
		indicatorsContainer: (base) => ({
			...base,
		}),
		indicatorSeparator: (base) => ({
			...base,
		}),
		input: (base) => ({
			...base,
			color: darkModeStatus ? _COLORS.dark.color : _COLORS.light.color,
		}),
		loadingIndicator: (base) => ({
			...base,
		}),
		loadingMessage: (base) => ({
			...base,
		}),
		// menu dropdown color
		menu: (base) => ({
			...base,
			color: darkModeStatus ? _COLORS.dark.menu.color : _COLORS.light.menu.color,
			backgroundColor: darkModeStatus
				? _COLORS.dark.menu.bgColor
				: _COLORS.light.menu.bgColor,
		}),
		// menu dropdown label color
		menuList: (base) => ({
			...base,
		}),
		menuPortal: (base) => ({
			...base,
			color: 'red',
			zIndex: 9999,
		}),
		// multivalue list
		multiValue: (base) => ({
			...base,
		}),
		// multivalue list label
		multiValueLabel: (base) => ({
			...base,
			borderRadius: '0px',
			color: darkModeStatus ? _COLORS.dark.color : _COLORS.light.color,
			backgroundColor: darkModeStatus
				? _COLORS.dark.multi.bgColor
				: _COLORS.light.multi.bgColor,
		}),
		// multivalue list button remove
		multiValueRemove: (base) => ({
			...base,
			borderRadius: '0px',
			color: darkModeStatus ? _COLORS.dark.color : _COLORS.light.color,
			backgroundColor: darkModeStatus
				? _COLORS.dark.multiRemove.bgColor
				: _COLORS.light.multiRemove.bgColor,
		}),
		noOptionsMessage: (base) => ({
			...base,
		}),
		option: (base, { isDisabled }) => ({
			...base,
			cursor: isDisabled ? 'not-allowed' : 'default',
		}),
		placeholder: (base) => ({
			...base,
		}),
		// color label value
		singleValue: (base) => ({
			...base,
			color: darkModeStatus ? _COLORS.dark.color : _COLORS.light.color,
		}),
		valueContainer: (base) => ({
			...base,
		}),
	};

	return (
		<>
			<ReactAsyncCreatableSelect
				defaultOptions
				loadOptions={loadOptions}
				placeholder={placeholder}
				id={id}
				name={name}
				className={className}
				styles={custom_style}
				theme={themes ?? themeSelect}
				isMulti={isMulti}
				isLoading={isLoading}
				isSearchable
				isClearable={isClearable}
				isDisabled={isDisable}
				defaultValue={defaultValue}
				options={options}
				onBlur={onBlur}
				onChange={onChange}
				onFocus={onFocus}
				onInput={onInput}
				onInvalid={onInvalid}
				onSelect={onSelect}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...props}
			/>
			{/* invalid component */}
			{!isValid && (isMulti ? value?.length === 0 : !value) && !isDisable && (
				<div style={{ color: COLORS.DANGER.code, fontSize: '0.875rem' }} className='mt-1'>
					{invalidFeedback}
				</div>
			)}
		</>
	);
};

AsyncCreatableSelect.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	/**
	 * If the name value is left blank, the id value is assigned.
	 */
	name: PropTypes.string,
	placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	/**
	 * Custom Props
	 */
	components: PropTypes.instanceOf(Object),
	styles: PropTypes.instanceOf(Object),
	themes: PropTypes.instanceOf(Object),
	isMulti: PropTypes.bool,
	isLoading: PropTypes.bool,
	isClearable: PropTypes.bool,
	isDisable: PropTypes.bool,
	isValid: PropTypes.bool,
	defaultOptions: PropTypes.bool,
	loadOptions: PropTypes.func,
	invalidFeedback: PropTypes.string,
	validFeedback: PropTypes.string,
	/**
	 * For formik ***`formik.values.ID_OR_NAME`***
	 */
	// value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.string),
		PropTypes.shape({
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		}),
		PropTypes.arrayOf(
			PropTypes.shape({
				value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
				text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
				label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			}),
		),
	]),
	defaultValue: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.instanceOf(Object),
		PropTypes.arrayOf(PropTypes.instanceOf(Object)),
	]),
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		}),
	),
	/**
	 * Fires the moment that the element loses focus. For formik ***`formik.handleBlur`***
	 */
	onBlur: PropTypes.func,
	/**
	 * Fires the moment when the value of the element is changed. For formik ***`formik.handleChange`***
	 */
	onChange: PropTypes.func,
	/**
	 * Fires the moment when the element gets focus
	 */
	onFocus: PropTypes.func,
	/**
	 * Script to be run when an element gets user input
	 */
	onInput: PropTypes.func,
	/**
	 * Script to be run when an element is invalid
	 */
	onInvalid: PropTypes.func,
	/**
	 * Fires after some text has been selected in an element
	 */
	onSelect: PropTypes.func,
};
AsyncCreatableSelect.defaultProps = {
	id: null,
	className: null,
	name: null,
	placeholder: 'Select..',
	components: null,
	styles: null,
	themes: null,
	isMulti: false,
	isLoading: false,
	isClearable: false,
	isDisable: false,
	isValid: true,
	defaultOptions: false,
	loadOptions: null,
	invalidFeedback: null,
	validFeedback: null,
	value: undefined,
	defaultValue: undefined,
	options: null,
	onBlur: null,
	onChange: null,
	onFocus: null,
	onInput: null,
	onInvalid: null,
	onSelect: null,
};

export const AsyncSelect = ({
	id,
	name,
	className,
	placeholder,
	components,
	styles,
	themes,
	isMulti,
	isLoading,
	isClearable,
	isDisable,
	isValid,
	defaultOptions,
	loadOptions,
	invalidFeedback,
	validFeedback,
	value,
	defaultValue,
	options,
	onBlur,
	onChange,
	onFocus,
	onInput,
	onInvalid,
	onSelect,
	...props
}) => {
	const { darkModeStatus } = useDarkMode();

	// modified style
	const custom_style = {
		...styles,
		clearIndicator: (base) => ({
			...base,
		}),
		container: (base) => ({
			...base,
		}),
		// main
		control: (base, { isDisabled, isFocused }) => ({
			...base,
			color: darkModeStatus ? _COLORS.dark.color : _COLORS.light.color,
			backgroundColor: controlBackgroundColor(darkModeStatus, isValid, isDisabled),
			borderColor: controlBorderColor(darkModeStatus, isValid, isDisabled, isFocused),
		}),
		// button dropdown
		dropdownIndicator: (base) => ({
			...base,
		}),
		group: (base) => ({
			...base,
		}),
		groupHeading: (base) => ({
			...base,
		}),
		indicatorsContainer: (base) => ({
			...base,
		}),
		indicatorSeparator: (base) => ({
			...base,
		}),
		input: (base) => ({
			...base,
			color: darkModeStatus ? _COLORS.dark.color : _COLORS.light.color,
		}),
		loadingIndicator: (base) => ({
			...base,
		}),
		loadingMessage: (base) => ({
			...base,
		}),
		// menu dropdown color
		menu: (base) => ({
			...base,
			color: darkModeStatus ? _COLORS.dark.menu.color : _COLORS.light.menu.color,
			backgroundColor: darkModeStatus
				? _COLORS.dark.menu.bgColor
				: _COLORS.light.menu.bgColor,
		}),
		// menu dropdown label color
		menuList: (base) => ({
			...base,
		}),
		menuPortal: (base) => ({
			...base,
			color: 'red',
			zIndex: 9999,
		}),
		// multivalue list
		multiValue: (base) => ({
			...base,
		}),
		// multivalue list label
		multiValueLabel: (base) => ({
			...base,
			borderRadius: '0px',
			color: darkModeStatus ? _COLORS.dark.color : _COLORS.light.color,
			backgroundColor: darkModeStatus
				? _COLORS.dark.multi.bgColor
				: _COLORS.light.multi.bgColor,
		}),
		// multivalue list button remove
		multiValueRemove: (base) => ({
			...base,
			borderRadius: '0px',
			color: darkModeStatus ? _COLORS.dark.color : _COLORS.light.color,
			backgroundColor: darkModeStatus
				? _COLORS.dark.multiRemove.bgColor
				: _COLORS.light.multiRemove.bgColor,
		}),
		noOptionsMessage: (base) => ({
			...base,
		}),
		option: (base, { isDisabled }) => ({
			...base,
			cursor: isDisabled ? 'not-allowed' : 'default',
		}),
		placeholder: (base) => ({
			...base,
		}),
		// color label value
		singleValue: (base) => ({
			...base,
			color: darkModeStatus ? _COLORS.dark.color : _COLORS.light.color,
		}),
		valueContainer: (base) => ({
			...base,
		}),
	};

	return (
		<>
			<ReactAsyncSelect
				defaultOptions
				loadOptions={loadOptions}
				placeholder={placeholder}
				id={id}
				name={name}
				className={className}
				styles={custom_style}
				theme={themes ?? themeSelect}
				isMulti={isMulti}
				isLoading={isLoading}
				isClearable={isClearable}
				isDisabled={isDisable}
				defaultValue={defaultValue}
				options={options}
				onBlur={onBlur}
				onChange={onChange}
				onFocus={onFocus}
				onInput={onInput}
				onInvalid={onInvalid}
				onSelect={onSelect}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...props}
			/>
			{/* invalid component */}
			{!isValid && !value && !isDisable && (
				<div style={{ color: COLORS.DANGER.code, fontSize: '0.875rem' }} className='mt-1'>
					{invalidFeedback}
				</div>
			)}
		</>
	);
};

AsyncSelect.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	/**
	 * If the name value is left blank, the id value is assigned.
	 */
	name: PropTypes.string,
	placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	/**
	 * Custom Props
	 */
	components: PropTypes.instanceOf(Object),
	styles: PropTypes.instanceOf(Object),
	themes: PropTypes.instanceOf(Object),
	isMulti: PropTypes.bool,
	isLoading: PropTypes.bool,
	isClearable: PropTypes.bool,
	isDisable: PropTypes.bool,
	isValid: PropTypes.bool,
	defaultOptions: PropTypes.bool,
	loadOptions: PropTypes.func,
	invalidFeedback: PropTypes.string,
	validFeedback: PropTypes.string,
	/**
	 * For formik ***`formik.values.ID_OR_NAME`***
	 */
	// value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.string),
		PropTypes.shape({
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		}),
		PropTypes.arrayOf(
			PropTypes.shape({
				value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
				text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
				label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			}),
		),
	]),
	defaultValue: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.instanceOf(Object),
		PropTypes.arrayOf(PropTypes.instanceOf(Object)),
	]),
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		}),
	),
	/**
	 * Fires the moment that the element loses focus. For formik ***`formik.handleBlur`***
	 */
	onBlur: PropTypes.func,
	/**
	 * Fires the moment when the value of the element is changed. For formik ***`formik.handleChange`***
	 */
	onChange: PropTypes.func,
	/**
	 * Fires the moment when the element gets focus
	 */
	onFocus: PropTypes.func,
	/**
	 * Script to be run when an element gets user input
	 */
	onInput: PropTypes.func,
	/**
	 * Script to be run when an element is invalid
	 */
	onInvalid: PropTypes.func,
	/**
	 * Fires after some text has been selected in an element
	 */
	onSelect: PropTypes.func,
};
AsyncSelect.defaultProps = {
	id: null,
	className: null,
	name: null,
	placeholder: 'Select..',
	components: null,
	styles: null,
	themes: null,
	isMulti: false,
	isLoading: false,
	isClearable: false,
	isDisable: false,
	isValid: true,
	defaultOptions: false,
	loadOptions: null,
	invalidFeedback: null,
	validFeedback: null,
	value: undefined,
	defaultValue: undefined,
	options: null,
	onBlur: null,
	onChange: null,
	onFocus: null,
	onInput: null,
	onInvalid: null,
	onSelect: null,
};

export const CreatableSelect = ({
	id,
	name,
	className,
	placeholder,
	components,
	styles,
	themes,
	isMulti,
	isLoading,
	isClearable,
	isDisable,
	isValid,
	invalidFeedback,
	validFeedback,
	value,
	defaultValue,
	options,
	onBlur,
	onChange,
	onFocus,
	onInput,
	onInvalid,
	onSelect,
	onCreateOption,
	...props
}) => {
	const { darkModeStatus } = useDarkMode();

	// modified style
	const custom_style = {
		...styles,
		clearIndicator: (base) => ({
			...base,
		}),
		container: (base) => ({
			...base,
		}),
		// main
		control: (base, { isDisabled, isFocused }) => ({
			...base,
			color: darkModeStatus ? _COLORS.dark.color : _COLORS.light.color,
			backgroundColor: controlBackgroundColor(darkModeStatus, isValid, isDisabled),
			borderColor: controlBorderColor(darkModeStatus, isValid, isDisabled, isFocused),
		}),
		// button dropdown
		dropdownIndicator: (base) => ({
			...base,
		}),
		group: (base) => ({
			...base,
		}),
		groupHeading: (base) => ({
			...base,
		}),
		indicatorsContainer: (base) => ({
			...base,
		}),
		indicatorSeparator: (base) => ({
			...base,
		}),
		input: (base) => ({
			...base,
			color: darkModeStatus ? _COLORS.dark.color : _COLORS.light.color,
		}),
		loadingIndicator: (base) => ({
			...base,
		}),
		loadingMessage: (base) => ({
			...base,
		}),
		// menu dropdown color
		menu: (base) => ({
			...base,
			color: darkModeStatus ? _COLORS.dark.menu.color : _COLORS.light.menu.color,
			backgroundColor: darkModeStatus
				? _COLORS.dark.menu.bgColor
				: _COLORS.light.menu.bgColor,
		}),
		// menu dropdown label color
		menuList: (base) => ({
			...base,
		}),
		menuPortal: (base) => ({
			...base,
			color: 'red',
			zIndex: 9999,
		}),
		// multivalue list
		multiValue: (base) => ({
			...base,
		}),
		// multivalue list label
		multiValueLabel: (base) => ({
			...base,
			borderRadius: '0px',
			color: darkModeStatus ? _COLORS.dark.color : _COLORS.light.color,
			backgroundColor: darkModeStatus
				? _COLORS.dark.multi.bgColor
				: _COLORS.light.multi.bgColor,
		}),
		// multivalue list button remove
		multiValueRemove: (base) => ({
			...base,
			borderRadius: '0px',
			color: darkModeStatus ? _COLORS.dark.color : _COLORS.light.color,
			backgroundColor: darkModeStatus
				? _COLORS.dark.multiRemove.bgColor
				: _COLORS.light.multiRemove.bgColor,
		}),
		noOptionsMessage: (base) => ({
			...base,
		}),
		option: (base, { isDisabled }) => ({
			...base,
			cursor: isDisabled ? 'not-allowed' : 'default',
		}),
		placeholder: (base) => ({
			...base,
		}),
		// color label value
		singleValue: (base) => ({
			...base,
			color: darkModeStatus ? _COLORS.dark.color : _COLORS.light.color,
		}),
		valueContainer: (base) => ({
			...base,
		}),
	};

	// animated remove options
	const createAnimated = animated();

	return (
		<>
			<ReactCreatableSelect
				id={id}
				name={name}
				className={className}
				placeholder={placeholder ?? 'Select...'}
				components={components ?? createAnimated}
				styles={custom_style}
				theme={themes ?? themeSelect}
				isMulti={isMulti}
				isLoading={isLoading}
				isSearchable
				isClearable={isClearable}
				isDisabled={isDisable}
				value={value}
				defaultValue={defaultValue}
				options={options}
				onBlur={onBlur}
				onChange={onChange}
				onFocus={onFocus}
				onInput={onInput}
				onInvalid={onInvalid}
				onSelect={onSelect}
				onCreateOption={onCreateOption}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...props}
			/>
			{/* invalid component */}
			{!isValid && value && !isDisable && (
				<div style={{ color: COLORS.DANGER.code, fontSize: '0.875rem' }} className='mt-1'>
					{invalidFeedback}
				</div>
			)}
		</>
	);
};

CreatableSelect.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	/**
	 * If the name value is left blank, the id value is assigned.
	 */
	name: PropTypes.string,
	placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	/**
	 * Custom Props
	 */
	components: PropTypes.instanceOf(Object),
	styles: PropTypes.instanceOf(Object),
	themes: PropTypes.instanceOf(Object),
	isMulti: PropTypes.bool,
	isLoading: PropTypes.bool,
	isClearable: PropTypes.bool,
	isDisable: PropTypes.bool,
	isValid: PropTypes.bool,
	invalidFeedback: PropTypes.string,
	validFeedback: PropTypes.string,
	/**
	 * For formik ***`formik.values.ID_OR_NAME`***
	 */
	// value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.string),
		PropTypes.shape({
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		}),
		PropTypes.arrayOf(
			PropTypes.shape({
				value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
				text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
				label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			}),
		),
	]),
	defaultValue: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.instanceOf(Object),
		PropTypes.arrayOf(PropTypes.instanceOf(Object)),
	]),
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		}),
	),
	/**
	 * Fires the moment that the element loses focus. For formik ***`formik.handleBlur`***
	 */
	onBlur: PropTypes.func,
	/**
	 * Fires the moment when the value of the element is changed. For formik ***`formik.handleChange`***
	 */
	onChange: PropTypes.func,
	/**
	 * Fires the moment when the element gets focus
	 */
	onFocus: PropTypes.func,
	/**
	 * Script to be run when an element gets user input
	 */
	onInput: PropTypes.func,
	/**
	 * Script to be run when an element is invalid
	 */
	onInvalid: PropTypes.func,
	/**
	 * Fires after some text has been selected in an element
	 */
	onSelect: PropTypes.func,
	/**
	 * Fires after some text has been selected in an element
	 */
	onCreateOption: PropTypes.func,
};
CreatableSelect.defaultProps = {
	id: null,
	className: null,
	name: null,
	placeholder: null,
	components: null,
	styles: null,
	themes: null,
	isMulti: false,
	isLoading: false,
	isClearable: false,
	isDisable: false,
	isValid: true,
	invalidFeedback: null,
	validFeedback: null,
	value: undefined,
	defaultValue: undefined,
	options: [],
	onBlur: null,
	onChange: null,
	onFocus: null,
	onInput: null,
	onInvalid: null,
	onSelect: null,
	onCreateOption: null,
};

const Select = ({
	id,
	name,
	className,
	placeholder,
	components,
	styles,
	themes,
	isMulti,
	isLoading,
	isSearchable,
	isClearable,
	isDisable,
	isValid,
	invalidFeedback,
	validFeedback,
	value,
	defaultValue,
	options,
	onBlur,
	onChange,
	onFocus,
	onInput,
	onInvalid,
	onSelect,
	...props
}) => {
	const { darkModeStatus } = useDarkMode();

	// modified style
	const custom_style = {
		...styles,
		clearIndicator: (base) => ({
			...base,
		}),
		container: (base) => ({
			...base,
		}),
		// main
		control: (base, { isDisabled, isFocused }) => ({
			...base,
			color: darkModeStatus ? _COLORS.dark.color : _COLORS.light.color,
			backgroundColor: controlBackgroundColor(darkModeStatus, isValid, isDisabled),
			borderColor: controlBorderColor(darkModeStatus, isValid, isDisabled, isFocused),
		}),
		// button dropdown
		dropdownIndicator: (base) => ({
			...base,
		}),
		group: (base) => ({
			...base,
		}),
		groupHeading: (base) => ({
			...base,
		}),
		indicatorsContainer: (base) => ({
			...base,
		}),
		indicatorSeparator: (base) => ({
			...base,
		}),
		input: (base) => ({
			...base,
			color: darkModeStatus ? _COLORS.dark.color : _COLORS.light.color,
		}),
		loadingIndicator: (base) => ({
			...base,
		}),
		loadingMessage: (base) => ({
			...base,
		}),
		// menu dropdown color
		menu: (base) => ({
			...base,
			color: darkModeStatus ? _COLORS.dark.menu.color : _COLORS.light.menu.color,
			backgroundColor: darkModeStatus
				? _COLORS.dark.menu.bgColor
				: _COLORS.light.menu.bgColor,
		}),
		// menu dropdown label color
		menuList: (base) => ({
			...base,
		}),
		menuPortal: (base) => ({
			...base,
			color: 'red',
			zIndex: 9999,
		}),
		// multivalue list
		multiValue: (base) => ({
			...base,
		}),
		// multivalue list label
		multiValueLabel: (base) => ({
			...base,
			borderRadius: '0px',
			color: darkModeStatus ? _COLORS.dark.color : _COLORS.light.color,
			backgroundColor: darkModeStatus
				? _COLORS.dark.multi.bgColor
				: _COLORS.light.multi.bgColor,
		}),
		// multivalue list button remove
		multiValueRemove: (base) => ({
			...base,
			borderRadius: '0px',
			color: darkModeStatus ? _COLORS.dark.color : _COLORS.light.color,
			backgroundColor: darkModeStatus
				? _COLORS.dark.multiRemove.bgColor
				: _COLORS.light.multiRemove.bgColor,
		}),
		noOptionsMessage: (base) => ({
			...base,
		}),
		option: (base, { isDisabled }) => ({
			...base,
			cursor: isDisabled ? 'not-allowed' : 'default',
		}),
		placeholder: (base) => ({
			...base,
		}),
		// color label value
		singleValue: (base) => ({
			...base,
			color: darkModeStatus ? _COLORS.dark.color : _COLORS.light.color,
		}),
		valueContainer: (base) => ({
			...base,
		}),
	};

	// animated remove options
	const createAnimated = animated();

	return (
		<>
			<ReactSelect
				id={id}
				name={name}
				className={className}
				placeholder={placeholder ?? 'Select...'}
				components={components ?? createAnimated}
				styles={custom_style}
				theme={themes ?? themeSelect}
				isMulti={isMulti}
				isLoading={isLoading}
				isSearchable={isSearchable}
				isClearable={isClearable}
				isDisabled={isDisable}
				value={value}
				defaultValue={defaultValue}
				options={options}
				onBlur={onBlur}
				onChange={onChange}
				onFocus={onFocus}
				onInput={onInput}
				onInvalid={onInvalid}
				onSelect={onSelect}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...props}
			/>
			{/* invalid component */}
			{!isValid && !value && !isDisable && (
				<div style={{ color: COLORS.DANGER.code, fontSize: '0.875rem' }} className='mt-1'>
					{invalidFeedback}
				</div>
			)}
		</>
	);
};

Select.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	/**
	 * If the name value is left blank, the id value is assigned.
	 */
	name: PropTypes.string,
	placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	/**
	 * Custom Props
	 */
	components: PropTypes.instanceOf(Object),
	styles: PropTypes.instanceOf(Object),
	themes: PropTypes.instanceOf(Object),
	isMulti: PropTypes.bool,
	isLoading: PropTypes.bool,
	isSearchable: PropTypes.bool,
	isClearable: PropTypes.bool,
	isDisable: PropTypes.bool,
	isValid: PropTypes.bool,
	invalidFeedback: PropTypes.string,
	validFeedback: PropTypes.string,
	/**
	 * For formik ***`formik.values.ID_OR_NAME`***
	 */
	// value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.string),
		PropTypes.shape({
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		}),
		PropTypes.arrayOf(
			PropTypes.shape({
				value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
				text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
				label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			}),
		),
	]),
	defaultValue: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.instanceOf(Object),
		PropTypes.arrayOf(PropTypes.instanceOf(Object)),
	]),
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		}),
	),
	/**
	 * Fires the moment that the element loses focus. For formik ***`formik.handleBlur`***
	 */
	onBlur: PropTypes.func,
	/**
	 * Fires the moment when the value of the element is changed. For formik ***`formik.handleChange`***
	 */
	onChange: PropTypes.func,
	/**
	 * Fires the moment when the element gets focus
	 */
	onFocus: PropTypes.func,
	/**
	 * Script to be run when an element gets user input
	 */
	onInput: PropTypes.func,
	/**
	 * Script to be run when an element is invalid
	 */
	onInvalid: PropTypes.func,
	/**
	 * Fires after some text has been selected in an element
	 */
	onSelect: PropTypes.func,
};
Select.defaultProps = {
	id: null,
	className: null,
	name: null,
	placeholder: null,
	components: null,
	styles: null,
	themes: null,
	isMulti: false,
	isLoading: false,
	isSearchable: false,
	isClearable: false,
	isDisable: false,
	isValid: true,
	invalidFeedback: null,
	validFeedback: null,
	value: undefined,
	defaultValue: undefined,
	options: [],
	onBlur: null,
	onChange: null,
	onFocus: null,
	onInput: null,
	onInvalid: null,
	onSelect: null,
};

export default Select;
