import axios from 'axios';
import authHeader from '../auth-header';

const findAllByBoardCode = async (boardCode, query) => {
	return axios.get(`${process.env.REACT_APP_API}task/${boardCode}`, {
		headers: await authHeader(),
		params: query,
	});
};

const findOneByTaskCode = async (taskCode) => {
	return axios.get(`${process.env.REACT_APP_API}task/one/${taskCode}`, {
		headers: await authHeader(),
	});
};

const create = async (boardCode, payload) => {
	return axios.post(`${process.env.REACT_APP_API}task/${boardCode}`, payload, {
		headers: await authHeader(),
	});
};

const update = async (taskCode, payload) => {
	return axios.patch(`${process.env.REACT_APP_API}task/${taskCode}`, payload, {
		headers: await authHeader(),
	});
};

const destroy = async (taskCode, payload) => {
	return axios.delete(`${process.env.REACT_APP_API}task/${taskCode}`, {
		headers: await authHeader(),
		data: payload,
	});
};

const findComment = async (taskCode, query) => {
	return axios.get(`${process.env.REACT_APP_API}task/${taskCode}/comment`, {
		headers: await authHeader(),
		params: query,
	});
};

const createComment = async (taskCode, payload) => {
	return axios.post(`${process.env.REACT_APP_API}task/${taskCode}/comment`, payload, {
		headers: await authHeader(),
	});
};

const updateComment = async (id, taskCode, payload) => {
	return axios.patch(`${process.env.REACT_APP_API}task/${taskCode}/comment/${id}`, payload, {
		headers: await authHeader(),
	});
};

const deleteComment = async (id, taskCode, payload) => {
	return axios.delete(`${process.env.REACT_APP_API}task/${taskCode}/comment/${id}`, {
		headers: await authHeader(),
		data: payload,
	});
};

export default {
	findAllByBoardCode,
	findOneByTaskCode,
	create,
	update,
	destroy,
	findComment,
	createComment,
	updateComment,
	deleteComment,
};
