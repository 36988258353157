import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight,
	SubheaderSeparator,
} from '../../../layout/SubHeader/SubHeader';
import PageLayoutHeader from '../../../pages/common/Headers/PageLayoutHeader';
import Button from '../../../components/bootstrap/Button';
import Page from '../../../layout/Page/Page';

import USERS from '../../../common/data/userDummyData';

import Avatar, { AvatarGroup } from '../../../components/Avatar';
import FormCreateMember from './FormCreateMember';
import FormProjectDescription from './FormProjectDescription';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import { AsyncSelect } from '../components/Select';
import DatePicker from '../components/DatePicker';
import Input from '../../../components/bootstrap/forms/Input';
import { getQueryParams, getRequester } from '../../../helpers/helpers';
import BoardModule from '../../../modules/bakti/BoardModule';
import TaskModule from '../../../modules/bakti/TaskModule';
import PriorityModule from '../../../modules/bakti/PriorityModule';
import GeneralSettingModule from '../../../modules/GeneralSettingModule';
import LoadingContent from '../components/LoadingContent';
import FormCreateTask from './FormCreateTask';
import BoardMenu from './BoardMenu';
import './index.scss';
import useDarkMode from '../../../hooks/useDarkMode';
import TaskBoard from './TaskBoard';
import { capitalizeFirstLetter, writeToLocalStorage } from '../utils';

const Index = () => {
	const navigate = useNavigate();
	const { username } = getRequester();

	const { darkModeStatus } = useDarkMode();
	const { board_code: boardCode } = getQueryParams();

	const [loading, setLoading] = useState(false);
	const [isReady, setIsReady] = useState(false);

	const [detailBoard, setDetailBoard] = useState(null);

	const [currentUser, setCurrentUser] = useState(null);
	const [boardData, setBoardData] = useState([]);
	const [options, setOptions] = useState({
		loading: false,
		reload: true,
	});

	const [openMenu, setOpenMenu] = useState(false);
	const [openAddMember, setOpenAddMember] = useState(false);
	const [openProject, setOpenProject] = useState(false);
	const [openCreateTask, setOpenCreateTask] = useState(false);

	const memberRef = useRef(null);

	const formikFilter = useFormik({
		initialValues: {
			member: null,
			date_start: null,
			date_end: null,
			search: '',
		},
		enableReinitialize: true,
		onReset: () => {
			memberRef.current?.clearValue();
			setOptions((e) => ({ ...e, reload: true }));
		},
		onSubmit: () => {
			setOptions((e) => ({ ...e, reload: true }));
		},
	});

	useEffect(() => {
		const fetch = async () => {
			try {
				fetchDefault();
				fetchData();
			} catch (err) {
				//
			}
		};

		if (options.reload && boardCode) {
			fetch();
		} else if (options.reload && !boardCode) {
			navigate('/board/workflow');
		}

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [options.reload]);

	// load setting default
	const fetchDefault = async () => {
		// load members
		BoardModule.findOneByBoardCode(boardCode)
			.then((response) => {
				const listMembers = [...response.members].map((user) => ({
					username: user?.username,
					name: user?.name,
					email: user?.email,
					role: user?.role,
					value: user?.username,
					label: `${user?.username}-${user?.name}`,
				}));
				setDetailBoard({ ...response, members: listMembers });

				writeToLocalStorage('board_members', listMembers);
				writeToLocalStorage('board_column', response?.board_column || []);
				writeToLocalStorage('board_key', response?.board_key);

				const findMember = listMembers.find((e) => e.username === username);
				writeToLocalStorage('board_user', findMember);
				setCurrentUser(findMember);
			})
			.catch(() => {
				setDetailBoard();
				writeToLocalStorage('board_user', '');
				writeToLocalStorage('board_members', []);
				writeToLocalStorage('board_column', []);
				writeToLocalStorage('board_key', '');
			})
			.finally(() => {});

		// form-task-attachment
		GeneralSettingModule.getFileTypeAndLimit({ type: 'form-task-attachment' })
			.then((response) => {
				writeToLocalStorage('task_attachment', response);
			})
			.catch(() => {
				writeToLocalStorage('task_attachment', {});
			})
			.finally(() => {});

		// load priority
		PriorityModule.read({ showAll: true })
			.then((response) => {
				writeToLocalStorage('board_priority', response.foundData);
			})
			.catch(() => {
				writeToLocalStorage('board_priority', []);
			})
			.finally(() => {});
	};

	// load data board
	const fetchData = async () => {
		setOptions((e) => ({ ...e, loading: true }));

		setLoading(true);
		setIsReady(false);

		const query = {};

		if (formikFilter.values.date_start) {
			query.date_start = formikFilter.values.date_start;
		}

		if (formikFilter.values.date_end) {
			query.date_end = formikFilter.values.date_end;
		}

		if (formikFilter.values.search) {
			query.search = formikFilter.values.search;
		}

		TaskModule.findAllByBoardCode(boardCode, query)
			.then((respponse) => {
				setBoardData(respponse);
				setIsReady(true);
			})
			.catch(() => {
				setBoardData({});
				setIsReady(false);
			})
			.finally(() => {
				setLoading(false);
				setOptions((e) => ({ ...e, loading: false, reload: false }));
			});
	};

	return (
		<PageWrapper title='Board'>
			<PageLayoutHeader />

			<SubHeader>
				<SubHeaderLeft>
					<Button
						color='info'
						isLink
						icon='ArrowBack'
						onClick={() =>
							navigate({ pathname: '/board/workflow' }, { replace: true })
						}>
						Back
					</Button>
					<SubheaderSeparator />
					{isReady && <span className='fw-bold'>{detailBoard?.title}</span>}
					{isReady && (
						<Button
							isLight
							icon='EditNote'
							color='success'
							onClick={() => setOpenProject(!openProject)}>
							Project Description
						</Button>
					)}
					{isReady && (
						<Button isLight icon='CalendarToday' color='info'>
							Schedule
						</Button>
					)}
				</SubHeaderLeft>
				<SubHeaderRight>
					<span className='me-4'>{capitalizeFirstLetter(currentUser?.role)}</span>
					{isReady && detailBoard?.members?.length > 0 && (
						<AvatarGroup>
							{detailBoard?.members?.map((member, index) => (
								<Avatar
									key={'user'.concat(index)}
									src={USERS.JOHN.src}
									srcSet={USERS.JOHN.srcSet}
									color={USERS.JOHN.color}
									userName={member.name}
								/>
							))}
						</AvatarGroup>
					)}

					{isReady && (
						<Button
							isLight
							icon='PersonAdd'
							color='primary'
							className='rounded-circle'
							onClick={() => setOpenAddMember(!openAddMember)}
						/>
					)}

					{isReady && (
						<Button
							isLight
							icon='MoreHoriz'
							color='primary'
							className='rounded-circle'
							onClick={() => setOpenMenu(true)}
						/>
					)}
				</SubHeaderRight>
			</SubHeader>

			<Page container='fluid'>
				<div className='row py-1'>
					<div className='col-sm-12 col-lg-4 col-xl-2 py-1'>
						<FormGroup id='member' label='Member'>
							<AsyncSelect
								ref={memberRef}
								placeholder='All'
								loadOptions={() => BoardModule.loadOptionsMember(boardCode)}
								cacheOptions
								defaultOptions
								defaultValue={formikFilter.values.member}
								value={formikFilter.values.member}
								onChange={(e) => formikFilter.setFieldValue('member', e)}
								isClearable
							/>
						</FormGroup>
					</div>
					<div className='col-sm-6 col-lg-4 col-xl-2 py-1'>
						<FormGroup id='date_start' label='Due Date (Start)'>
							<DatePicker
								placeholder='YYYY-MM-DD'
								dateFormat='yyyy-MM-dd'
								maxDate={formikFilter.values.date_end}
								value={formikFilter.values.date_start}
								onChange={(value) =>
									formikFilter.setFieldValue('date_start', value)
								}
							/>
						</FormGroup>
					</div>
					<div className='col-sm-6 col-lg-4 col-xl-2 py-1'>
						<FormGroup id='date_end' label='Due Date (End)'>
							<DatePicker
								placeholder='YYYY-MM-DD'
								dateFormat='yyyy-MM-dd'
								minDate={formikFilter.values.date_start}
								value={formikFilter.values.date_end}
								onChange={(value) => formikFilter.setFieldValue('date_end', value)}
							/>
						</FormGroup>
					</div>
					<div className='col-sm-12 col-lg-6 col-xl-2 py-1 blank-space'>
						<FormGroup id='search'>
							<Input
								placeholder='Search by Task'
								autoComplete='off'
								onChange={formikFilter.handleChange}
								onBlur={formikFilter.handleBlur}
								value={formikFilter.values.search}
								onKeyDown={(e) => {
									if (e.key === 'Enter') {
										formikFilter.handleSubmit(e);
									}
								}}
							/>
						</FormGroup>
					</div>
					<div className='col-sm-12 col-lg-6 col-xl-4 py-1 blank-space'>
						<Button
							isLight={darkModeStatus}
							icon='Search'
							color='primary'
							type='button'
							onClick={formikFilter.handleSubmit}
							className='mx-1'>
							Filter
						</Button>

						<Button
							isLight={darkModeStatus}
							icon='Restore'
							color='warning'
							type='button'
							onClick={formikFilter.resetForm}
							className='mx-1'>
							Reset
						</Button>

						{isReady && (
							<Button
								isLight={darkModeStatus}
								icon='Add'
								color='info'
								type='button'
								onClick={() => setOpenCreateTask(!openCreateTask)}
								className='mx-1 float-end'>
								Create Task
							</Button>
						)}
					</div>
				</div>

				{isReady && !loading && (
					<div className='board row mx-n4 pb-3 px-3'>
						<TaskBoard
							data={boardData}
							setData={setBoardData}
							onReload={() => {
								setTimeout(
									() => setOptions((prev) => ({ ...prev, reload: true })),
									300,
								);
							}}
						/>
					</div>
				)}

				{!isReady && loading && <LoadingContent onlyContent />}
			</Page>

			<BoardMenu
				setIsOpen={setOpenMenu}
				isOpen={openMenu}
				data={detailBoard}
				onReload={() => {
					setTimeout(() => setOptions((prev) => ({ ...prev, reload: true })), 300);
				}}
			/>

			<FormCreateMember setIsOpen={setOpenAddMember} isOpen={openAddMember} />

			<FormProjectDescription
				setIsOpen={setOpenProject}
				isOpen={openProject}
				boardCode={boardCode}
			/>

			<FormCreateTask
				isOpen={openCreateTask}
				setIsOpen={setOpenCreateTask}
				title='Create Task'
				onSuccess={() => {
					setTimeout(() => setOptions((prev) => ({ ...prev, reload: true })), 300);
				}}
			/>
		</PageWrapper>
	);
};

Index.propTypes = {};
Index.defaultProps = {};

export default Index;
