import React from 'react';
import PropTypes from 'prop-types';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../../components/bootstrap/Modal';
import Select, { AsyncSelect } from '../components/Select';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Button from '../../../components/bootstrap/Button';
import ListGroup, { ListGroupItem } from '../../../components/bootstrap/ListGroup';
import Badge from '../../../components/bootstrap/Badge';
import Avatar from '../../../components/Avatar';

import USERS from '../../../common/data/userDummyData';

export const MemberOptions = [
	{ value: 'owner', label: 'Owner', color: 'info', rank: 10 },
	{ value: 'member', label: 'Member', color: 'success', rank: 20 },
	{ value: 'spectator', label: 'Spectator', color: 'warning', rank: 30 },
	{ value: 'guest', label: 'Guest', color: 'light', rank: 40 },
];

const FormCreateMember = ({ isOpen, setIsOpen, title, size, id }) => {
	return (
		<Modal
			setIsOpen={setIsOpen}
			isOpen={isOpen}
			titleId={id}
			size={size}
			isCentered
			isFocus={false}
			enableEscape={false}
			isStaticBackdrop>
			<ModalHeader setIsOpen={setIsOpen}>
				<ModalTitle id={id}>{title}</ModalTitle>
			</ModalHeader>
			<ModalBody>
				<div className='row py-1'>
					<div className='col-sm-12 col-lg-7'>
						<FormGroup id='employee' label='Employee'>
							<AsyncSelect placeholder='Type here..' />
						</FormGroup>
					</div>

					<div className='col-sm-10 col-lg-4'>
						<FormGroup id='role' label='Role'>
							<Select placeholder='Select one' options={MemberOptions} />
						</FormGroup>
					</div>

					<div className='col-sm-2 col-lg-1' style={{ marginTop: '25px' }}>
						<Button color='success' type='button'>
							Add
						</Button>
					</div>
				</div>

				<div className='py-1 mt-3'>
					<ListGroup isFlush>
						<ListGroupItem>
							<div className='d-flex align-items-center'>
								<div className='flex-grow-1'>
									<div className='d-flex'>
										<div className='flex-shrink-0'>
											<Avatar
												src={USERS.JOHN.src}
												srcSet={USERS.JOHN.srcSet}
												color={USERS.JOHN.color}
												size={36}
											/>
										</div>
										<div className='mx-2'>
											<div>Faizudin Wasis Prabowo</div>
											<div className='small text-muted'>LNK000632</div>
										</div>
									</div>
								</div>

								<div className='mx-1'>
									<Badge color='info'>Owner</Badge>
								</div>
							</div>
						</ListGroupItem>

						<ListGroupItem>
							<div className='d-flex align-items-center'>
								<div className='flex-grow-1'>
									<div className='d-flex'>
										<div className='flex-shrink-0'>
											<Avatar
												src={USERS.JOHN.src}
												srcSet={USERS.JOHN.srcSet}
												color={USERS.JOHN.color}
												size={36}
											/>
										</div>
										<div className='mx-2'>
											<div>Faizudin Wasis Prabowo</div>
											<div className='small text-muted'>LNK000632</div>
										</div>
									</div>
								</div>
								<div className='mx-1'>
									<Badge color='success'>Member</Badge>
								</div>
								<div className='mx-1'>
									<Button
										isOutline
										icon='Close'
										color='danger'
										type='button'
										size='sm'
									/>
								</div>
							</div>
						</ListGroupItem>
					</ListGroup>
				</div>
			</ModalBody>
			<ModalFooter>
				<Button isLink color='info' type='button' className='mx-1'>
					Close
				</Button>

				<Button icon='Save' color='info' type='button' className='mx-1'>
					Save
				</Button>
			</ModalFooter>
		</Modal>
	);
};

FormCreateMember.propTypes = {
	id: PropTypes.string,
	title: PropTypes.string,
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
	size: PropTypes.oneOf([null, 'sm', 'lg', 'xl', 'xxl']),
};
FormCreateMember.defaultProps = {
	id: 'formAddMember',
	title: 'Add Member',
	size: 'lg',
};

export default FormCreateMember;
