import React from 'react';
import PropTypes from 'prop-types';
import Spinner from '../../../components/bootstrap/Spinner';
import Card, { CardBody } from '../../../components/bootstrap/Card';

const LoadingContent = ({ onlyContent }) => {
	const renderContent = (
		<div className='d-flex w-100 h-100 justify-content-center align-items-center'>
			<Spinner isGrow color='info' />
			&nbsp;
			<Spinner isGrow color='info' />
			&nbsp;
			<Spinner isGrow color='info' />
			&nbsp;
			<Spinner isGrow color='info' />
			&nbsp;
			<Spinner isGrow color='info' />
		</div>
	);

	if (onlyContent) return renderContent;

	return (
		<Card stretch shadow='none'>
			<CardBody>{renderContent}</CardBody>
		</Card>
	);
};

LoadingContent.propTypes = {
	onlyContent: PropTypes.bool,
};
LoadingContent.defaultProps = {
	onlyContent: false,
};

export default LoadingContent;
