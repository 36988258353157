import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import PageLayoutHeader from '../../../pages/common/Headers/PageLayoutHeader';
import Page from '../../../layout/Page/Page';
import SubHeader, { SubHeaderLeft, SubheaderSeparator } from '../../../layout/SubHeader/SubHeader';
import useDarkMode from '../../../hooks/useDarkMode';
import ScheduleModule from '../../../modules/afif/ScheduleModule';
import showNotification from '../../../components/extras/showNotification';
import Item from './components/Item';
import DetailModal from './components/DetailModal';
import FormModal from './components/FormModal';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Spinner from '../../../components/bootstrap/Spinner';
import LoadingModal from '../../../components/custom/LoadingModal';

const createFunction = (values) => {
	return new Promise((resolve, reject) => {
		try {
			ScheduleModule.create(values)
				.then((res) => {
					Swal.fire({
						heightAuto: false,
						title: 'Information!',
						text: res?.message ?? 'Data has been created successfully',
						icon: 'success',
					});
					resolve({ error: false, message: 'successfully' });
				})
				.catch((err) => {
					Swal.fire({ heightAuto: false, title: 'Warning!', text: err, icon: 'error' });
					reject(new Error(err));
				});
		} catch (e) {
			reject(new Error(e.message));
		}
	});
};

const editFunction = (values) => {
	return new Promise((resolve, reject) => {
		try {
			ScheduleModule.update(values)
				.then((res) => {
					Swal.fire({
						heightAuto: false,
						title: 'Information!',
						text: res?.message ?? 'Data has been updated successfully',
						icon: 'success',
					});
					resolve({ error: false, message: 'successfully' });
				})
				.catch((err) => {
					Swal.fire({ heightAuto: false, title: 'Warning!', text: err, icon: 'error' });
					reject(new Error(err));
				});
		} catch (e) {
			reject(new Error(e.message));
		}
	});
};

const deleteFunction = (values) => {
	return new Promise((resolve, reject) => {
		try {
			Swal.fire({
				heightAuto: false,
				title: 'Are you sure?',
				text: "You won't be able to revert this!",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, delete it!',
				reverseButtons: true,
			}).then((result) => {
				if (result.isConfirmed)
					ScheduleModule.softDelete(values)
						.then((res) => {
							Swal.fire({
								heightAuto: false,
								title: 'Information!',
								text: res?.message ?? 'Data has been deleted successfully',
								icon: 'success',
							});
							resolve({ error: false, message: 'successfully' });
						})
						.catch((err) => {
							Swal.fire({
								heightAuto: false,
								title: 'Warning!',
								text: err,
								icon: 'error',
							});
							reject(new Error(err));
						});
				else reject(new Error('cancelled'));
			});
		} catch (e) {
			reject(new Error(e.message));
		}
	});
};

const FilterCustom = ({ setFilter }) => {
	const { darkModeStatus } = useDarkMode();

	const formik = useFormik({
		initialValues: {
			search: '',
			startDate: null,
			endDate: null,
		},
		onReset: () => {
			setFilter(formik.initialValues);
		},
		onSubmit: (values) => {
			setFilter(values);
		},
	});

	return (
		<>
			<div className='col-auto' style={{ maxWidth: '10rem' }}>
				<FormGroup label='Date Start'>
					<DatePicker
						autoComplete='off'
						wrapperClassName='w-100'
						selected={formik.values.startDate}
						onChange={(e) => {
							formik.setFieldValue('startDate', e);
						}}
						selectsStart
						startDate={formik.values.startDate}
						endDate={formik.values.startDate}
						customInput={<Input type='text' placeholder='dd/mm/yyyy' />}
					/>
				</FormGroup>
			</div>
			<div className='col-auto' style={{ maxWidth: '10rem' }}>
				<FormGroup label='Date End'>
					<DatePicker
						autoComplete='off'
						wrapperClassName='w-100'
						selected={formik.values.endDate}
						onChange={(e) => {
							formik.setFieldValue('endDate', e);
						}}
						selectsEnd
						startDate={formik.values.startDate}
						endDate={formik.values.endDate}
						minDate={formik.values.startDate}
						customInput={<Input type='text' placeholder='dd/mm/yyyy' />}
					/>
				</FormGroup>
			</div>
			<div className='col-auto' style={{ minWidth: '16rem' }}>
				<FormGroup>
					<Input
						name='search'
						autoComplete='off'
						placeholder='Search by Title'
						value={formik.values?.search}
						onKeyUp={(e) => {
							if (e.key === 'Enter') formik.handleSubmit(e);
						}}
						onChange={formik.handleChange}
					/>
				</FormGroup>
			</div>
			<div className='col-auto d-flex'>
				<Button
					icon='Search'
					color='primary'
					type='button'
					isLight={darkModeStatus}
					onClick={formik.handleSubmit}>
					Search
				</Button>
			</div>
			<div className='col-auto d-flex'>
				<Button
					icon='Refresh'
					color='warning'
					type='button'
					isLight={darkModeStatus}
					className='mx-1'
					onClick={formik.handleReset}>
					Reset
				</Button>
			</div>
		</>
	);
};
FilterCustom.propTypes = {
	setFilter: PropTypes.func,
};
FilterCustom.defaultProps = {
	setFilter: () => {},
};

const Schedule = () => {
	const { darkModeStatus } = useDarkMode();
	const { boardCode, taskCode } = useParams();

	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState([]);
	const [filter, setFilter] = useState({});

	const [isActionLoading, setIsActionLoading] = useState(false);
	const [isFormOpen, setIsFormOpen] = useState(false);
	const [formOptions, setFormOptions] = useState({
		initialValues: {},
		title: 'Form Modal',
		handleCustomSubmit: () => {},
	});

	const [selectedItem, setSelectedItem] = useState({});
	const [isDetailOpen, setIsDetailOpen] = useState(false);

	const fetchData = async () => {
		setIsLoading(true);

		return ScheduleModule.list({
			...filter,
			boardCode,
			taskCode,
			groupByMonth: true,
		})
			.then((res) => {
				setData(res.data.filter((d) => d.values.length > 0));
			})
			.catch((err) => {
				showNotification('Warning!', err, 'danger');
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const handleCreate = () => {
		setFormOptions(() => ({
			title: 'Create Schedule',
			initialValues: {
				title: '',
				date: '',
				is_all_day: false,
				time: {
					start: '',
					end: '',
				},
				repeat: null,
				reminder: [],
				assign_to: [],
				location: '',
				description: '',
			},
			handleCustomSubmit: (values, handleReset) => {
				setIsActionLoading(true);
				createFunction(values)
					.then(() => {
						handleReset();
						fetchData();
					})
					.catch(() => {})
					.finally(() => {
						setIsActionLoading(false);
					});
			},
		}));
	};

	const handleEdit = (item) => {
		setFormOptions(() => ({
			title: 'Edit Schedule',
			initialValues: {
				_id: item._id,
				title: item.title,
				date: item.date,
				is_all_day: item.is_all_day,
				time: item.time,
				repeat: item.repeat,
				reminder: item.reminder,
				assign_to: item.assign_to,
				location: item.location,
				description: item?.description,
			},
			handleCustomSubmit: (values, handleReset) => {
				setIsActionLoading(true);
				editFunction(values)
					.then(() => {
						handleReset();
						fetchData();
					})
					.catch(() => {})
					.finally(() => {
						setIsActionLoading(false);
					});
			},
		}));
	};

	const handleDetail = (item) => {
		setSelectedItem(() => ({ ...item }));
	};

	const handleDelete = ({ _id }) => {
		deleteFunction({ _id })
			.then(() => {
				fetchData();
			})
			.catch(() => {});
	};

	useEffect(() => {
		if (boardCode && taskCode) {
			fetchData(boardCode, taskCode);
		} else {
			showNotification('Warning!', 'Board code and task code must be specified', 'danger');
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filter]);

	useEffect(() => {
		if (formOptions.title !== 'Form Modal') setIsFormOpen(true);
	}, [formOptions]);

	useEffect(() => {
		if (Object.keys(selectedItem).length !== 0) setIsDetailOpen(true);
	}, [selectedItem]);

	return (
		<PageWrapper title='Schedule'>
			<PageLayoutHeader />
			<SubHeader className='row'>
				<SubHeaderLeft className='col-sm'>
					<Button color='info' isLink icon='ArrowBack'>
						Back
					</Button>
					<SubheaderSeparator />
					<span className='fw-bold'>Schedule</span>
				</SubHeaderLeft>
			</SubHeader>
			<Page>
				<LoadingModal isLoading={isActionLoading} setLoading={setIsActionLoading} />
				<DetailModal
					isOpen={isDetailOpen}
					setIsOpen={setIsDetailOpen}
					item={selectedItem}
				/>
				<FormModal
					isOpen={isFormOpen}
					setIsOpen={setIsFormOpen}
					type='board'
					title={formOptions.title}
					initialValues={formOptions.initialValues}
					handleCustomSubmit={formOptions.handleCustomSubmit}
					boardCode={boardCode}
				/>
				<div className='row align-items-end'>
					{isLoading && (
						<div className='col-auto me-3'>
							<Spinner color='info' />
						</div>
					)}
					<FilterCustom setFilter={setFilter} />
					<div className='col'>
						<Button
							id='ButtonCreateNew'
							icon='Add'
							type='button'
							color='info'
							isLight={darkModeStatus}
							className='float-end'
							onClick={handleCreate}>
							Create New
						</Button>
					</div>
				</div>
				<div className='row'>
					{data.map((d) => (
						<div key={d.key} className='col-12'>
							<h3 className='fw-bold py-3'>{d.key}</h3>
							{d.values.map((item) => (
								<Item
									key={`${d.key}-Item-${d._id}`}
									item={item}
									handleDetail={handleDetail}
									handleEdit={handleEdit}
									handleDelete={handleDelete}
								/>
							))}
						</div>
					))}
				</div>
			</Page>
		</PageWrapper>
	);
};

export default Schedule;
