import axios from 'axios';
import authHeader from '../auth-header';

const API_URL_DEFAULT = process.env.REACT_APP_API;
const APP_MODEL = 'master-segment';

const create = async (payload) => {
	return axios.post(`${API_URL_DEFAULT}${APP_MODEL}`, payload, { headers: await authHeader() });
};
const read = async (query_string) => {
	return axios.get(`${API_URL_DEFAULT}${APP_MODEL}/?${query_string}`, {
		headers: await authHeader(),
	});
};
const readSelect = async (query_string) => {
	return axios.get(`${API_URL_DEFAULT}${APP_MODEL}/select/?${query_string}`, {
		headers: await authHeader(),
	});
};
const readSelectNew = async (query_string) => {
	return axios.get(`${API_URL_DEFAULT}${APP_MODEL}/select-new/?${query_string}`, {
		headers: await authHeader(),
	});
};
const readPIC = async (query_string) => {
	return axios.get(`${API_URL_DEFAULT}${APP_MODEL}/read-pic/?${query_string}`, {
		headers: await authHeader(),
	});
};
const readPICOrg = async (query_string) => {
	return axios.get(`${API_URL_DEFAULT}${APP_MODEL}/read-pic-org/?${query_string}`, {
		headers: await authHeader(),
	});
};
const readOrganization = async (query_string) => {
	return axios.get(`${API_URL_DEFAULT}${APP_MODEL}/read-organization/?${query_string}`, {
		headers: await authHeader(),
	});
};
const readOrganizationWithMasterSegment = async (query_string) => {
	return axios.get(`${API_URL_DEFAULT}${APP_MODEL}/read-organization-segment/?${query_string}`, {
		headers: await authHeader(),
	});
};
const readOrganizationWithMasterSegmentNew = async (query_string) => {
	return axios.get(
		`${API_URL_DEFAULT}${APP_MODEL}/read-organization-segment-new/?${query_string}`,
		{
			headers: await authHeader(),
		},
	);
};

export default {
	create,
	read,
	readSelect,
	readSelectNew,
	readPIC,
	readPICOrg,
	readOrganization,
	readOrganizationWithMasterSegment,
	readOrganizationWithMasterSegmentNew,
};
