import axios from 'axios';
import authHeader from '../auth-header';

const API_URL_DEFAULT = process.env.REACT_APP_API;
const APP_MODEL = 'schedule';

const list = async (query) => {
	return axios.get(`${API_URL_DEFAULT}${APP_MODEL}`, {
		headers: await authHeader(),
		params: query,
	});
};

const create = async (payload) => {
	return axios.post(`${API_URL_DEFAULT}${APP_MODEL}`, payload, { headers: await authHeader() });
};

const update = async (payload) => {
	return axios.put(`${API_URL_DEFAULT}${APP_MODEL}`, payload, { headers: await authHeader() });
};

const softDelete = async (payload) => {
	return axios.delete(`${API_URL_DEFAULT}${APP_MODEL}`, {
		data: payload,
		headers: await authHeader(),
	});
};

export default { list, create, update, softDelete };
